import React, { useState } from "react";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useForm from "react-hook-form";
import api from "../../services/api";
import { RoleStruct, PlantStruct } from "../../types/types";

interface Props extends ModalProps {
	roles: Array<RoleStruct>;
	data: PlantStruct;
	onHide: any;
}
function* renderRoles(roles) {
	for (let index = 0; index < roles.length; index++) {
		const role = roles[index];
		if (role.deletable) {
			yield (
				<option key={role._id} value={role._id}>
					{role.name}
				</option>
			);
		}
	}
}

export default function AddUserModal(props: Props) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { register, handleSubmit, watch, errors, formState, reset } = useForm();
	const [error, setError] = useState("");
	const [success, setSuccess] = useState(false);
	const onSubmit = async (data) => {
		try {
			const { email, role } = data;
			await api.post(`/plans/${props.data.id}/link-user`, {
				email,
				role,
			});
			setSuccess(true);
			reset();
		} catch (e) {
			if (e.response != null) {
				setError(e.response.data);
			} else {
				setError(
					"Verifique sua conexão com a internet e tente novamente T.T"
				);
			}
		}
	};
	return (
		<Modal
			{...props}
			size="sm"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{success ? `Usuário associado` : `Associar Usuário`}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{success ? (
					<div>O Usuário foi associado com sucesso</div>
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						{error && (
							<div className="alert alert-danger" role="alert">
								{error}
							</div>
						)}
						<input
							className="form-control"
							type="email"
							maxLength={40}
							name="email"
							ref={register({
								required: "Obrigatório",
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
									message: "Endereço de e-mail inválido",
								},
							})}
							placeholder="E-mail"
						/>
						<select
							className="form-control mt-2"
							name="role"
							ref={register({
								required: "Obrigatório",
							})}
							placeholder="Selecione um papel"
						>
							{Array.from(renderRoles(props.roles))}
						</select>
						{errors.email && errors.email.message}
						<div style={{ marginTop: "30px", float: "right" }}>
							<Button type="submit">Salvar</Button>
						</div>
					</form>
				)}
				{success && (
					<Button
						type="button"
						onClick={() => {
							props.onHide();
							setSuccess(false);
						}}
					>
						OK
					</Button>
				)}
			</Modal.Body>
			<Modal.Footer></Modal.Footer>
		</Modal>
	);
}
