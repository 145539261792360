import { Container } from "unstated";

interface AppState {
	showPremium: Boolean;
}

class AppContainer extends Container<AppState> {
	state = {
		showPremium: false
	};
}

export default AppContainer;
