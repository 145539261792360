import React, { Component } from "react";
import styled from "styled-components";
import user from "../../assets/user.svg";
import { Table, Button } from "react-bootstrap";
import api from "../../services/api";
const Container = styled.div`
	padding: 20px;
`;

const ContainerRound = styled.div`
	min-height: 90px;
	width: 100%;
	padding: 20px;
	background: #3a3848 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 15px #00000029;
	border-radius: 10px;
`;

interface Props {}

interface State {
	plants: Array<{
		id: string;
		status: string;
		name: string;
		owner: string;
	}>;
}

export class Users extends Component<Props, State> {
	state = {
		plants: new Array<{
			id: string;
			status: string;
			name: string;
			owner: string;
			premium: boolean;
		}>()
	};
	async componentDidMount() {
		await this.fetchData();
	}

	async fetchData() {
		let response = await api.get("plans/all");
		let plants = response.data.map(item => {
			return {
				name: item.name,
				id: item._id,
				premium: item.premium !== undefined ? item.premium : false,
				status: item.status.toString(),
				owner: `"${item.user.name}" ${item.user.email}`
			};
		});
		this.setState({ plants });
	}

	async togglePremium(id, value) {
		let resp = window.confirm(
			`Tem certeza que deseja ${value ? "habilitar" : "desabilitar"}?`
		);
		if (resp) {
			await api.patch(`plans/${id}/premium`, {
				premium: value
			});
			await this.fetchData();
		}
	}

	render() {
		return (
			<div>
				<Container>
					<ContainerRound className="d-flex align-items-center justify-content-between">
						<div className="d-flex align-items-center">
							<img alt="Plantas" height="32" src={user} />
							<h2 className="ml-3 mb-0">Plantas</h2>
						</div>
					</ContainerRound>
					<ContainerRound
						className="p-2 d-flex flex-wrap"
						style={{ marginTop: 20 }}
					>
						<Table striped variant="dark" responsive hover>
							<thead>
								<tr>
									<th>#</th>
									<th>Planta</th>
									<th>Usuário</th>
									<th>Premium</th>
									<th>Ações</th>
								</tr>
							</thead>
							<tbody>
								{this.state.plants.map(item => {
									return (
										<tr>
											<td>{item.id}</td>
											<td>{item.name}</td>
											<td>{item.owner}</td>
											<td>
												{item.premium ? "Sim" : "Não"}
											</td>
											<td>
												{item.premium ? (
													<Button
														onClick={() => {
															this.togglePremium(
																item.id,
																false
															);
														}}
														variant="danger"
														size="sm"
													>
														Desabilitar Premium
													</Button>
												) : (
													<Button
														onClick={() => {
															this.togglePremium(
																item.id,
																true
															);
														}}
														size="sm"
													>
														Habilitar Premium
													</Button>
												)}
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</ContainerRound>
				</Container>
			</div>
		);
	}
}

export default Users;
