import React, { Component } from "react";
import Plant from "../../components/Plant";
import styled from "styled-components";
import Scrollbar from "react-scrollbars-custom";
import { PlantStruct } from "../../types/types";
import PlantContainer from "../../containers/PlantContainer";
import { Subscribe } from "unstated";
import { getAuth } from "../../services/auth";
import { withRouter, RouteComponentProps } from "react-router-dom";

const Wrapper = styled.div`
	padding: 30px 0px 30px 0px;
	width: auto;
	white-space: nowrap;
`;

type PathParamsType = {};

type PropsType = RouteComponentProps<PathParamsType> & {};

interface State {
	plants: Array<PlantStruct>;
}

export class Plants extends Component<PropsType, State> {
	*renderPlants(container) {
		for (let index = 0; index < container.state.plants.length; index++) {
			const plant = container.state.plants[index] as PlantStruct;
			yield (
				<Plant
					loading={container.state.loading}
					key={plant.id}
					data={plant}
				/>
			);
		}
	}

	componentWillUnmount() {
		document.body.style.overflow = "unset";
	}

	componentWillMount() {
		document.body.style.overflow = "hidden";
	}
	async componentDidMount() {
		let auth = getAuth();
		if (auth.user.role === "5bb6c21eb2ca821ed84b7c93") {
			this.props.history.replace("/configuracoes");
		}
	}

	render() {
		return (
			<Subscribe to={[PlantContainer]}>
				{(plantContainer) => (
					<Scrollbar
						className="touchScroll"
						style={{
							width: "100vw",
							height: "100%",
						}}
						thumbXProps={{
							renderer: (props) => {
								const { elementRef, ...restProps } = props;
								return (
									<div
										{...restProps}
										ref={elementRef}
										className="ThUmBX"
									></div>
								);
							},
						}}
						trackXProps={{
							renderer: (props) => {
								const { elementRef, ...restProps } = props;
								return (
									<div
										{...restProps}
										ref={elementRef}
										className="TrackX"
									/>
								);
							},
						}}
						trackYProps={{
							renderer: (props) => {
								const { elementRef, ...restProps } = props;
								return (
									<div
										{...restProps}
										ref={elementRef}
										className="trackY"
									/>
								);
							},
						}}
						thumbYProps={{
							renderer: (props) => {
								const { elementRef, ...restProps } = props;
								return (
									<div
										{...restProps}
										ref={elementRef}
										className="tHuMbY"
									/>
								);
							},
						}}
					>
						<Wrapper className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row">
							{Array.from(this.renderPlants(plantContainer))}
						</Wrapper>
					</Scrollbar>
				)}
			</Subscribe>
		);
	}
}

export default withRouter(Plants);
