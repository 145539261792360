import React, { useState } from "react";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useForm from "react-hook-form";
import api from "../../services/api";
import { getAuth, setAuth } from "../../services/auth";
import { PlantStruct } from "../../types/types";

interface Props extends ModalProps {
	plantid: string;
	plantname: string;
	data: PlantStruct;
}

export default function ChangePlantNameModal(props: Props) {
	const { register, handleSubmit, watch, errors } = useForm();
	const [error, setError] = useState("");
	const [plantName, setPlantName] = useState(props.plantname);
	const onSubmit = async data => {
		try {
			let userAuth = getAuth();
			let plans = userAuth.user!["plans"].map(item => {
				if (item.plan == props.plantid) {
					return {
						status: 0,
						plan: item.plan,
						alias: plantName,
						role: item.role,
						actions: item.actions
					};
				} else {
					return {
						status: 0,
						plan: item.plan,
						alias: item.alias,
						role: item.role,
						actions: item.actions
					};
				}
			});
			userAuth.user.plans = plans;
			setAuth(JSON.stringify(userAuth));
			await api.patch(`/users/${userAuth.user._id}`, {
				_id: userAuth.user._id,
				plans: plans
			});
			props.data.name = data.name;
			let { onHide } = props;
			if (onHide != null) {
				onHide();
			}
		} catch (e) {
			if (e.response != null) {
				setError(e.response.data);
			} else {
				setError(
					"Verifique sua conexão com a internet e tente novamente T.T"
				);
			}
		}
	};
	return (
		<Modal
			{...props}
			size="sm"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Alterar nome
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form onSubmit={handleSubmit(onSubmit)}>
					<p>
						Digite o nome da nova planta (Essa alteração é apenas
						para visualização do usuário)
					</p>
					{error && (
						<div className="alert alert-danger" role="alert">
							{error}
						</div>
					)}
					<input
						className="form-control"
						type="text"
						maxLength={40}
						value={plantName}
						onChange={e => {
							setPlantName(e.target.value);
						}}
						name="name"
						ref={register({
							required: "Obrigatório"
						})}
						placeholder="Nome"
					/>
					{errors.name && errors.name.message}
					<div style={{ marginTop: "30px", float: "right" }}>
						<Button type="submit">Salvar</Button>
					</div>
				</form>
			</Modal.Body>
			<Modal.Footer></Modal.Footer>
		</Modal>
	);
}
