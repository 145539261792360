import React from "react";
import "./App.scss";
import Routes from "./routes";
import moment from "moment";
import momentPtBR from "moment/locale/pt-br";
import { registerLocale } from "react-datepicker";
import Numeral from "numeral";
import "numeral/locales/pt-br";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt";

Numeral.locale('pt-br');
registerLocale("pt-BR", pt);

moment.locale("pt-br", momentPtBR);
const App: React.FC = () => {
	return <Routes />;
};

export default App;
