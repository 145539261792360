import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";

import { isAuthenticated } from "./services/auth";

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			isAuthenticated() ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{ pathname: "/", state: { from: props.location } }}
				/>
			)
		}
	/>
);

const baseName =
	process.env.REACT_APP_ENVIROMENT === "staging" ||
	process.env.REACT_APP_ENVIROMENT === "develop"
		? "/app"
		: "/app";

const Routes = () => (
	<BrowserRouter basename={baseName}>
		<Switch>
			<Route exact path="/" component={Login} />
			<PrivateRoute path="/dashboard" component={Dashboard} />
			<PrivateRoute
				path="/plant/:plantId/barriers/:barrierId/devices/:deviceId"
				component={Dashboard}
			/>
			<PrivateRoute
				path={`/plant/:plantId/barriers/:barrierId/sensors/:deviceId`}
				component={Dashboard}
			/>
			<PrivateRoute path="/configuracoes" component={Dashboard} />
			<PrivateRoute path="/configuracoes/papeis" component={Dashboard} />
			<PrivateRoute path="/configuracoes/usuario" component={Dashboard} />
			<PrivateRoute path="/usuarios" component={Dashboard} />
			<PrivateRoute
				path="/configuracoes/alterar-senha-local"
				component={Dashboard}
			/>
			<PrivateRoute path="/relatorios" component={Dashboard} />
			<PrivateRoute
				path="/relatorios/estoque-anterior"
				component={Dashboard}
			/>
			<PrivateRoute path="/relatorios/entrada-estoque" component={Dashboard} />
			<PrivateRoute
				path="/relatorios/estoque-anterior"
				component={Dashboard}
			/>
			<Route path="*" component={() => <h1>Página não encontrada</h1>} />
		</Switch>
	</BrowserRouter>
);

export default Routes;
