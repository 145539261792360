import React, { Component, useState } from "react";
import styled from "styled-components";
import userImage from "../../assets/user.svg";
import { getAuth, setAuth } from "../../services/auth";
import api from "../../services/api";
import useForm from "react-hook-form";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { generatePasswordCrypt } from "../../utils/passwordUtils";
import { Subscribe } from "unstated";
import UserContainer from "../../containers/UserContainer";
import { Button } from "react-bootstrap";

const Container = styled.div`
	padding: 20px;
`;

const ContainerRound = styled.div`
	min-height: 90px;
	width: 100%;
	padding: 20px;
	background: #3a3848 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 15px #00000029;
	border-radius: 10px;
`;


type PathParamsType = {};
type Props = RouteComponentProps<PathParamsType> & {};

function User(props: Props) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [user, setUser] = useState(getAuth().user);
	const [getEmail, setEmail] = useState(user.email);
	const [getName, setName] = useState(user.name);
	const [getPhone, setPhone] = useState(user.phone);
	const [getPassword, setPassword] = useState("");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { register, handleSubmit, watch, errors, formState } = useForm();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [error, setError] = useState("");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [success, setSuccess] = useState(false);
	const onSubmit = async data => {
		try {
			const { name, phone, email } = data;
			let userAuth = getAuth();
			let plans = user!["plans"].map(item => {
				return {
					status: 0,
					plan: item.plan,
					alias: item.alias,
					role: item.role,
					actions: item.actions
				};
			});
			let params = {
				_id: user!["_id"],
				name,
				phone,
				email: email,
				plans: plans
			};
			if (name == user!["name"]) {
				delete params.name;
			} else {
				userAuth.user.name = name;
			}
			if (phone == user!["phone"]) {
				delete params.phone;
			} else {
				userAuth.user.phone = phone;
			}
			if (email == user!["email"]) {
				delete params.email;
			} else {
				userAuth.user.email = email;
			}
			if (getPassword !== "" && getPassword !== undefined) {
				params["show_password"] = getPassword;
				params["password"] = await generatePasswordCrypt(getPassword);
			}
			setAuth(JSON.stringify(userAuth));
			await api.patch(`/users/${params._id}`, params);
			props.history.goBack();
		} catch (e) {
			const err = e as any;
			if (err.response != null) {
				setError(err.response.data);
			} else {
				setError(
					"Verifique sua conexão com a internet e tente novamente T.T"
				);
			}
		}
	};
	return (
		<Subscribe to={[UserContainer]}>
			{userContainer => (
				<Container>
					<ContainerRound className="d-flex align-items-center justify-content-between">
						<div className="d-flex align-items-center">
							<img height="32" src={userImage} />
							<h2 className="ml-3 mb-0">Editar Perfil</h2>
						</div>
					</ContainerRound>
					<ContainerRound
						style={{ marginTop: 20 }}
						className="p-4 d-flex justify-content-center"
					>
						<form
							onSubmit={handleSubmit(onSubmit)}
							className="w-50"
						>
							<div className="form-group">
								<label htmlFor="name">Nome</label>
								<input
									id="name"
									value={getName}
									name="name"
									ref={register({
										required: "Obrigatório"
									})}
									onChange={item => {
										setName(item.target.value);
									}}
									placeholder="Nome"
									type="text"
									className="form-control"
								/>
							</div>
							<div className="form-group">
								<label htmlFor="email">Email</label>
								<input
									id="email"
									name="email"
									onChange={item => {
										setEmail(item.target.value);
									}}
									ref={register({
										required: "Obrigatório"
									})}
									value={getEmail}
									placeholder="Email"
									type="text"
									className="form-control"
								/>
							</div>
							<div className="form-group">
								<label htmlFor="password">Senha</label>
								<input
									id="password"
									onChange={item => {
										setPassword(item.target.value);
									}}
									value={getPassword}
									name="password"
									placeholder="Senha"
									type="password"
									className="form-control"
								/>
							</div>
							<div className="form-group">
								<label htmlFor="phone">Telefone</label>
								<input
									id="phone"
									name="phone"
									onChange={item => {
										setPhone(item.target.value);
									}}
									ref={register({
										required: "Obrigatório"
									})}
									value={getPhone}
									placeholder="Telefone"
									type="text"
									className="form-control"
								/>
							</div>
							<div className="form-group row">
								<div className="col-12 d-flex justify-content-end">
									<Button
										onClick={() => {
											userContainer.setState({
												user: {
													id:
														userContainer.state.user
															.id,
													name: getName
												}
											});
										}}
										type="submit"
									>
										Salvar
									</Button>
								</div>
							</div>
						</form>
					</ContainerRound>
				</Container>
			)}
		</Subscribe>
	);
}

export default withRouter(User);
