import React from "react";
import styled from "styled-components";
import { SensorStruct } from "../../types/types";
import moment from "moment";
import { withRouter, RouteComponentProps } from "react-router-dom";
import AppContainer from "../../containers/AppContainer";
import { Subscribe } from "unstated";

type PathParamsType = {};

type Props = RouteComponentProps<PathParamsType> & {
	expanded: Boolean;
	data: SensorStruct;
	plantIndex: Number;
	vlinkIndex: Number;
	premium: Boolean;
	connected: Boolean;
	updatedAt: Date;
};

type StyledProps = {
	expanded: Boolean;
	connected: Boolean;
	data: SensorStruct;
	
};

const getColor = (
	value: Boolean,
	problem: Boolean,
	recognized: Boolean,
	connected: Boolean
) => {
	if (!connected) {
		return "gray";
	}
	if (value && !problem && recognized) {
		return "#54D92C";
	} else if (!value && recognized) {
		return "#FF0303";
	} else {
		return "#FB9700";
	}
};

const SensorStatus = styled.div`
	width: ${(p: StyledProps) => (p.expanded ? 30 : 20)}px;
	height: ${(p: StyledProps) => (p.expanded ? 30 : 20)}px;
	border-radius: 100%;
	border: ${(p: StyledProps) => (p.expanded ? "1px solid #BBBFC6" : "none")}
	background-color: ${(props) =>
		getColor(
			props.data.value,
			props.data.problem,
			props.data.recognized,
			props.connected
		) || "white"};
`;
const Title = styled.div`
	color: #fff;
	font-size: 14px;
	font-weight: bold;
`;

const Refresh = styled.div`
	font-size: "10px";
	color: "#6f6f6f";
`;

function Sensor(props: Props) {
	let statusText = () => {
		if (props.data.value && !props.data.problem && props.data.recognized) {
			return "Normal";
		} else if (!props.data.value && props.data.recognized) {
			return "Líquido detectado";
		} else {
			return "Falha de Conexão";
		}
	};
	return (
		<Subscribe to={[AppContainer]}>
			{(appContainer) => (
				<div
					onClick={() => {
						if (props.premium) {
							props.history.push(
								`/plant/${props.plantIndex}/barriers/${props.vlinkIndex}/sensors/${props.data.index}`
							);
						} else {
							appContainer.setState({
								showPremium: true,
							});
						}
					}}
					className="d-flex"
					style={{ marginTop: props.expanded ? 5 : 5 }}
				>
					<SensorStatus
						connected={props.connected}
						className="ml-2"
						expanded={props.expanded}
						data={props.data}
					/>
					{props.expanded && (
						<div style={{ marginLeft: 10 }}>
							<Title>
								{props.data.name} - {statusText()}
							</Title>
							<Refresh>
								Última Leitura{" "}
								{moment(props.updatedAt).format("DD/MM/YYYY - HH:mm:ss")}
							</Refresh>
						</div>
					)}
				</div>
			)}
		</Subscribe>
	);
}

export default withRouter(Sensor);
