import { TextEncoderLite } from "text-encoder-lite";
export const generatePasswordCrypt = async password => {
	var enc = new TextEncoderLite("utf-8");
	var key = await window.crypto.subtle.importKey(
		"raw",
		enc.encode("devoz"),
		{
			name: "HMAC",
			hash: { name: "SHA-256" }
		},
		false,
		["sign", "verify"]
	);
	var signature = await window.crypto.subtle.sign(
		"HMAC",
		key,
		enc.encode(password)
	);
	var b = new Uint8Array(signature);
	var hmacpass = await Array.prototype.map
		.call(b, x => ("00" + x.toString(16)).slice(-2))
		.join("");
	return hmacpass;
};
