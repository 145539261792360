import axios from "axios";
import { getToken, logout } from "./auth";
const baseURL = process.env.REACT_APP_API_URL;
const win: Window = window;
const api = axios.create({
	baseURL,
});
axios.defaults.headers.post["Content-Type"] = "application/json";
api.interceptors.request.use(async (config) => {
	const token = getToken();
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
});

api.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.response != null) {
			if (error.response.config.url === `${baseURL}/login`) {
				return Promise.reject(error);
			}
			if (401 === error.response.status) {
				logout();
				win.location.assign("/");
			} else {
				return Promise.reject(error);
			}
		} else {
			return Promise.reject(error);
		}
	}
);

export default api;
