import { Container } from "unstated";
import { PlantStruct } from "../types/types";

interface PlantState {
	plants: Array<PlantStruct>;
	loading: Boolean;
}

class PlantContainer extends Container<PlantState> {
	state = {
		plants: [],
		loading: true
	};

	syncPlants(plants: Array<PlantStruct>) {
		this.setState({ plants });
	}
}

export default PlantContainer;
