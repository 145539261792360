import React, { Component } from "react";
import styled from "styled-components";
import { Button, Modal } from "react-bootstrap";
import water from "../../assets/water.svg";
import Select from "react-select";
import pdfMake from "pdfmake/build/pdfmake";
import { vfs } from "../../utils/vfs_fonts";
import moment from "moment";
import api from "../../services/api";
import { getAuth } from "../../services/auth";
import Numeral from "numeral";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { exportCSVFile } from "../../utils/export";

const Container = styled.div`
	padding: 20px;
`;

const ContainerRound = styled.div`
	min-height: 90px;
	width: 100%;
	padding: 20px;
	background: #3a3848 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 15px #00000029;
	border-radius: 10px;
`;

type State = {
	plants: undefined | Array<{ value: string; label: string }>;
	selectedPlants: Array<any>;
	generating: boolean;
};

type Props = {};

export class ReportConsolidated extends Component<Props, State> {
	state = {
		plants: undefined,
		selectedPlants: new Array<any>(),
		generating: false,
	};

	async componentDidMount() {
		let response = await api.get("/plans/getUserAllPremiums");
		let items: Array<{ label: string; value: string }> = response.data.map(
			(item) => {
				return {
					value: item._id,
					label: item.name,
				};
			}
		);
		this.setState({
			plants: items,
		});
	}

	onSubmit = async (event) => {
		event.preventDefault();
	};

	normalizetext = (text) => {
		var weird = 'öüóőúéáàűíÖÜÓŐÚÉÁÀŰÍçÇ!@£$%^&*()_+?/*."';
		var normalized = "ouooueaauiOUOOUEAAUIcC                 ";
		var idoff = -1,
			new_text = "";
		var lentext = text.toString().length - 1;

		for (let i = 0; i <= lentext; i++) {
			idoff = weird.search(text.charAt(i));
			if (idoff == -1) {
				new_text = new_text + text.charAt(i);
			} else {
				new_text = new_text + normalized.charAt(idoff);
			}
		}
		return new_text;
	};

	generateData = async (type: number) => {
		if (this.state.selectedPlants.length === 0) {
			alert("Selecione ao menos uma planta");
			return;
		}
		try {
			this.setState({
				generating: true,
			});
			let response = await api.post("/plans/report-consolidated", {
				plans: this.state.selectedPlants.map((item) => {
					return item.value;
				}),
			});
			if (type === 1) {
				this.generatePDF(response.data);
			} else {
				this.generateCSV(response.data);
			}
		} catch (e) {
		} finally {
			this.setState({
				generating: false,
			});
		}
	};

	generatePDF(data: any) {
		let email = getAuth().user.email;
		pdfMake.vfs = vfs;
		pdfMake.fonts = {
			Roboto: {
				normal: "Roboto-Regular.ttf",
				bold: "Roboto-Bold.ttf",
				italics: "Roboto-Italic.ttf",
				bolditalics: "Roboto-BoldItalic.ttf",
			},
		};

		let content = data.map((item, index) => {
			let tanks = item.data.filter((item) => {
				return item.type === "volume";
			});
			let sensors = item.data.filter((item) => {
				return item.type === "sensor";
			});
			let rowsTanks = tanks.map((row) => {
				return [
					row.name,
					`${Numeral(row.capacity).format("0,0")}L`,
					row.stock != -1 && row.stock != null
						? `${Numeral(row.stock).format("0,0")}L`
						: "Indisponivel",
					row.stock != -1 && row.stock != null
						? `${Numeral(row.available).format("0,0")}L`
						: "Indisponivel",
				];
			});
			let rowsSensors = sensors.map((row) => {
				return [row.name, row.last_reading.dry ? "Seco" : "Molhado"];
			});
			let items: any[] = [
				{
					margin: [0, index > 0 ? 40 : 0, 0, 0],
					text: `${item.name} - ${moment().format(
						"DD/MM/YYYY HH:mm"
					)}`,
					style: "header",
				},
				{
					text: `Usuário: ${email}`,
					style: "header",
					margin: [0, 5, 0, 0],
				},
				{
					text: "CONSOLIDADO",
					style: "header",
					margin: [0, 20, 0, 0],
				},
			];
			if (rowsTanks.length > 0) {
				items.push({
					text: "Tanques",
					bold: true,
					margin: [0, 20, 0, 0],
				});
				items.push({
					layout: "noBorders",
					table: {
						headerRows: 1,
						widths: ["*", "*", "*", "*"],

						body: [
							[
								{ text: "Produto:", bold: true },
								{ text: "Capacidade:", bold: true },
								{ text: "Estoque:", bold: true },
								{
									text: "Disponibilidade:",
									bold: true,
								},
							],
							...rowsTanks,
						],
					},
				});
			}

			if (rowsSensors.length > 0) {
				items.push({
					layout: "noBorders",
					margin: [0, 20, 0, 0],
					table: {
						headerRows: 1,
						widths: ["*", "*"],

						body: [
							[
								{
									text: "Sensoriamento:",
									bold: true,
								},
								{ text: "", bold: true },
							],
							...rowsSensors,
						],
					},
				});
			}
			return items;
		});

		var docDefinition = {
			info: {
				title: "Relatório Consolidado",
			},
			content: content,
			defaultStyle: {
				font: "Roboto",
				fontSize: 8,
			},
			styles: {
				header: {
					fontSize: 12,
					bold: true,
				},
			},
		};
		try {
			pdfMake
				.createPdf(docDefinition)
				.download("relatorio-consolidado.pdf");
		} catch (err) {
			console.error(err);
		}
	}

	generateCSV(data: any) {
		let content = data
			.map((item, index) => {
				let tanks = item.data.filter((item) => {
					return item.type === "volume";
				});
				let sensors = item.data.filter((item) => {
					return item.type === "sensor";
				});
				let rowsTanks = tanks.map((row) => {
					return {
						plant: this.normalizetext(item.name).replace(
							/(\r\n|\n|\r)/gm,
							""
						),
						name: this.normalizetext(row.name).replace(
							/(\r\n|\n|\r)/gm,
							""
						),
						property1: `${Numeral(row.capacity).format("0,0")}L`,
						property2:
							row.stock != -1 && row.stock != null
								? `${Numeral(row.stock).format("0,0")}L`
								: "Indisponivel",
						property3:
							row.stock != -1 && row.stock != null
								? `${Numeral(row.available).format("0,0")}L`
								: "Indisponivel",
						property4: "",
						date: moment().format("DD/MM/YYYY HH:mm:ss"),
					};
				});
				let rowsSensors = sensors.map((row) => {
					return {
						plant: this.normalizetext(item.name).replace(
							/(\r\n|\n|\r)/gm,
							""
						),
						name: this.normalizetext(row.name).replace(
							/(\r\n|\n|\r)/gm,
							""
						),
						property1: "",
						property2: "",
						property3: "",
						property4:
							row.last_reading != null
								? row.last_reading.dry
									? "Seco"
									: "Molhado"
								: "Indisponivel",
						date: moment().format("DD/MM/YYYY HH:mm:ss"),
					};
				});
				return rowsTanks.concat(rowsSensors);
			})
			.reduce((a, b) => a.concat(b), []);
		var headersCSV = {
			plant: "PLANTA",
			name: "DISPOSITIVO",
			property1: "CAPACIDADE",
			property2: "ESTOQUE",
			property3: "DISPONIBILIDADE",
			property4: "STATUS DO SENSOR",
			date: "DATA/HORA DO RELATÓRIO",
		};
		exportCSVFile(headersCSV, content, "relatorio-consolidado");
	}

	render() {
		return (
			<div>
				<Modal
					show={this.state.generating}
					onHide={() => {
						this.setState({
							generating: false,
						});
					}}
				>
					<Modal.Header>
						<Modal.Title>Aguarde...</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p className="text-center">
							<FontAwesomeIcon
								inverse
								size="2x"
								icon={faSync}
								spin
								className="mr-3"
							/>{" "}
							Gerando Relatório
						</p>
					</Modal.Body>
				</Modal>
				<Container>
					<ContainerRound className="d-flex align-items-center justify-content-between">
						<div className="d-flex align-items-center">
							<img alt="Papéis" height="32" src={water} />
							<h2 className="ml-3 mb-0">Relatório Consolidado</h2>
						</div>
					</ContainerRound>
					<ContainerRound
						className="p-2 d-flex"
						style={{ marginTop: 20 }}
					>
						<form onSubmit={this.onSubmit} className="m-auto w-75">
							<div className="row">
								<div className="col-md">
									<label className="mr-2">Plantas</label>
									<Select
										className="fg-black"
										placeholder="Selecione uma planta"
										noOptionsMessage={() => {
											return "Sem plantas selecionadas";
										}}
										isMulti={true}
										value={this.state.selectedPlants}
										onChange={(selected) => {
											this.setState({
												selectedPlants: selected,
											});
										}}
										options={this.state.plants}
									/>
								</div>
								<div className="col-md mt-4">
									<Button
										className=" ml-md-3"
										onClick={() => this.generateData(1)}
										type="button"
									>
										Gerar PDF
									</Button>
									<Button
										className="ml-3"
										onClick={() => this.generateData(2)}
										variant="secondary"
										type="button"
									>
										Gerar CSV
									</Button>
								</div>
							</div>
						</form>
					</ContainerRound>
				</Container>
			</div>
		);
	}
}

export default ReportConsolidated;
