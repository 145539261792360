import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useForm from "react-hook-form";
import api from "../../services/api";
import axios, { AxiosError } from "axios";

export default function ForgotPassword(props) {
	const { register, handleSubmit, watch, errors, formState } = useForm();
	const [error, setError] = useState("");
	const [email, setEmail] = useState("");
	const [success, setSuccess] = useState(false);
	const onSubmit = async (data) => {
		try {
			const { email } = data;
			await api.post("/users/recover-password", {
				email,
			});
			setSuccess(true);
		} catch (e) {
			const err = e as any;
			if (err.response != null) {
				if (err.response.status == 404) {
					setError("Verifique o e-mail digitado");
				} else {
					setError(
						"Verifique sua conexão com a internet e tente novamente T.T"
					);
				}
			} else {
				setError("Verifique sua conexão com a internet e tente novamente T.T");
			}
		}
	};
	return (
		<Modal
			{...props}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Recuperar Senha
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{success ? (
					<p>
						Um email foi enviado para {email} com instruções de recuperação de
						senha.
					</p>
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						<p>
							Para recuperar sua senha digite o e-mail associado a sua conta
							abaixo e você receberá um e-mail com instruções de redefinição de
							senha
						</p>
						{error && (
							<div className="alert alert-danger" role="alert">
								{error}
							</div>
						)}
						<input
							className="form-control"
							type="email"
							name="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							ref={register({
								required: "Obrigatório",
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
									message: "Endereço de e-mail inválido",
								},
							})}
							placeholder="Email"
						/>
						{errors.email && errors.email.message}
						<div style={{ marginTop: "30px", float: "right" }}>
							<Button type="submit">Enviar</Button>
						</div>
					</form>
				)}
			</Modal.Body>
			<Modal.Footer>
				{success && (
					<Button
						onClick={() => {
							props.onHide();
							setEmail("");
							setSuccess(false);
						}}
					>
						OK
					</Button>
				)}
			</Modal.Footer>
		</Modal>
	);
}
