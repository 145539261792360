import React from "react";
import { Container, Form } from "./styles";
import logo from "../../assets/logo.svg";
import ForgotPassword from "../../components/ForgotPassword";
import Signup from "../../components/Signup";
import { withRouter, RouteComponentProps } from "react-router-dom";
import api from "../../services/api";
import { login } from "../../services/auth";
import { isAuthenticated } from "../../services/auth";
import { generatePasswordCrypt } from "../../utils/passwordUtils";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosError } from "axios";

export interface State {}

type PathParamsType = {};
type PropsType = RouteComponentProps<PathParamsType> & {};
class Login extends React.Component<PropsType, State> {
	emailRef = React.createRef<HTMLInputElement>();
	state = {
		error: "",
		email: "",
		password: "",
		showPassword: false,
		confirmedRegister: false,
		remember: false,
		modalShow: false,
		modalRegisterShow: false,
	};
	constructor(props) {
		super(props);
		if (isAuthenticated()) {
			this.props.history.replace("/dashboard");
		}
	}

	componentWillMount() {
		var paramsQuery = new URLSearchParams(this.props.location.search);
		if (paramsQuery.get("conf") == "1") {
			this.setState({
				confirmedRegister: true,
			});
		}
		let body = document.querySelector("body");
		if (body) {
			body.classList.add("login");
		}
	}

	componentWillUnmount() {
		let body = document.querySelector("body");
		if (body) {
			body.classList.remove("login");
		}
	}

	handleSignIn = async (event) => {
		event.stopPropagation();
		event.preventDefault();
		const { email, password, remember } = this.state;
		if (!email || !password) {
			this.setState({
				error: "Preencha e-mail e senha para continuar!",
				confirmedRegister: false,
			});
		} else {
			try {
				let cryptedPassword = await generatePasswordCrypt(password);
				const response = await api.post("/login", {
					email: email.toLowerCase(),
					password: cryptedPassword,
					autologin: true,
				});
				login(
					JSON.stringify(response.data),
					email.toLowerCase(),
					cryptedPassword,
					remember
				);
				this.props.history.push("/dashboard");
			} catch (err) {
				const error = err as any
				if (this.emailRef.current != null) {
					this.emailRef.current.focus();
				}
				if (error.response && error.response.data) {
					this.setState({
						error: error.response.data,
						confirmedRegister: false,
					});
				} else {
					this.setState({
						confirmedRegister: false,
						error:
							"Houve um problema com o login, verifique suas credenciais. T.T",
					});
				}
			}
		}
	};
	render() {
		return (
			<Container>
				<ForgotPassword
					show={this.state.modalShow}
					onHide={() => {
						this.setState({ modalShow: false });
					}}
				/>
				<Signup
					show={this.state.modalRegisterShow}
					onHide={() => this.setState({ modalRegisterShow: false })}
				/>
				<Form onSubmit={this.handleSignIn}>
					<img src={logo} alt="NKL SIV" />
					<h1>Acessar SIV</h1>
					{this.state.error && (
						<div className="alert alert-danger" role="alert">
							{this.state.error}
						</div>
					)}
					{this.state.confirmedRegister && (
						<div className="alert alert-info" role="alert">
							Seu cadastro foi confirmado, faça login com seu e-mail e senha
						</div>
					)}
					<label>E-mail</label>
					<input
						type="email"
						ref={this.emailRef}
						placeholder="Digite seu e-mail"
						onChange={(e) => this.setState({ email: e.target.value })}
					/>
					<label>Senha</label>
					<div className="input-group mb-3">
						<input
							type={!this.state.showPassword ? "password" : "text"}
							className="form-control"
							placeholder="Digite sua senha"
							onChange={(e) => this.setState({ password: e.target.value })}
						/>
						<div className="input-group-append">
							<a
								href="#"
								className="btn btn-secondary btn-show-password"
								onClick={(e) => {
									e.preventDefault();
									this.setState({
										showPassword: !this.state.showPassword,
									});
								}}
							>
								<FontAwesomeIcon
									icon={!this.state.showPassword ? faEye : faEyeSlash}
								/>
							</a>
						</div>
					</div>
					<button
						type="button"
						onClick={() => {
							this.setState({ modalShow: true });
						}}
						className="forgot-password"
					>
						Esqueci minha senha
					</button>
					<div className="form-check d-flex justify-content-between">
						<label
							htmlFor="chk-remember-me"
							style={{ width: "auto" }}
							className="form-check-label"
						>
							Lembrar de mim
						</label>
						<div>
							<input
								type="checkbox"
								onChange={(e) => {
									this.setState({
										remember: e.target.checked,
									});
								}}
								id="chk-remember-me"
								className="css-checkbox"
							/>
							<label htmlFor="chk-remember-me" className="css-label"></label>
						</div>
					</div>
					<div className="d-flex justify-content-between buttons-actions">
						<button
							className="btn btn-secondary"
							type="button"
							onClick={() => {
								this.setState({ modalRegisterShow: true });
							}}
						>
							Nova conta
						</button>
						<button type="submit" className="btn btn-primary">
							Entrar
						</button>
					</div>
					<p className="mt-4 text-center">
						Desenvolvido por NKL Produtos Eletrônicos Ltda - &nbsp;
						<a rel="noopener" target="_blank" href="http://www.nkl.com.br">
							www.nkl.com.br
						</a>
					</p>
				</Form>
			</Container>
		);
	}
}

export default withRouter(Login);
