import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useForm from "react-hook-form";
import api from "../../services/api";
import { generatePasswordCrypt } from "../../utils/passwordUtils";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Signup(props) {
	const { register, handleSubmit, watch, errors, reset } = useForm();
	const [error, setError] = useState("");
	const [success, setSuccess] = useState(false);
	const [showPassword, setShowPassoword] = useState(false);
	const closeModal = () => {
		props.onHide();
		setSuccess(false);
		reset();
	};
	const onSubmit = async (data) => {
		try {
			const { email, name, password, phone } = data;
			await api.post("/users/create", {
				address: "",
				created_at: null,
				email: email.toLowerCase(),
				name,
				password: await generatePasswordCrypt(password),
				password_show: password,
				phone: phone,
				role: "",
				updated_at: null,
				_id: "SIVWeb.model.User-3",
			});
			setSuccess(true);
		} catch (e) {
			const err = e as any
			if (err.response != null) {
				if (err.response && err.response.data) {
					setError(err.response.data.message);
					return;
				}
			}
			setError("Verifique sua conexão com a internet e tente novamente T.T");
		}
	};
	return (
		<Modal
			{...props}
			size="md"
			ariaLabelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{success ? `Usuário criado com sucesso` : `Nova Conta`}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{success ? (
					<div>
						<p>
							Você receberá um e-mail para validar a criação do seu usuário,
							clique sobre o link para validar.
						</p>
						<Button onClick={closeModal} type="button">
							OK
						</Button>
					</div>
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						<p>Para registrar uma nova conta, digite os dados abaixo:</p>
						{error && (
							<div className="alert alert-danger" role="alert">
								{error}
							</div>
						)}
						<div className="form-group">
							<label>Nome*</label>
							<input
								className="form-control"
								type="text"
								name="name"
								ref={register({
									required: "Obrigatório",
								})}
								placeholder="Digite seu nome"
							/>
						</div>
						<div className="form-group">
							<label>E-mail*</label>
							<input
								className="form-control"
								type="email"
								name="email"
								ref={register({
									required: "Obrigatório",
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
										message: "Endereço de e-mail inválido",
									},
								})}
								placeholder="Digite seu e-mail"
							/>
							{errors.email && errors.email.message}
						</div>
						<div className="form-group">
							<label>Senha</label>
							<div className="input-group mb-3">
								<input
									className="form-control"
									type={!showPassword ? "password" : "text"}
									name="password"
									ref={register({
										required: "Obrigatório",
									})}
									placeholder="Digite a senha"
								/>
								<div className="input-group-append">
									<button
										className="btn btn-secondary btn-show-password"
										onClick={(e) => {
											e.preventDefault();
											setShowPassoword(!showPassword);
										}}
									>
										<FontAwesomeIcon
											icon={!showPassword ? faEye : faEyeSlash}
										/>
									</button>
								</div>
							</div>
						</div>
						<div className="form-group">
							<label>Confirmação de Senha</label>
							<div className="input-group mb-3">
								<input
									className="form-control"
									type={!showPassword ? "password" : "text"}
									name="passwordConfim"
									ref={register({
										validate: (value) => value === watch("password"),
									})}
									placeholder="Digite a confirmação de senha"
								/>
								<div className="input-group-append">
									<button
										className="btn btn-secondary btn-show-password"
										onClick={(e) => {
											e.preventDefault();
											setShowPassoword(!showPassword);
										}}
									>
										<FontAwesomeIcon
											icon={!showPassword ? faEye : faEyeSlash}
										/>
									</button>
								</div>
							</div>
						</div>
						<div className="form-group">
							<label>Telefone</label>
							<input
								className="form-control"
								type="phone"
								name="phone"
								ref={register({
									required: "Obrigatório",
								})}
								placeholder="Digite o telefone"
							/>
						</div>
						<div style={{ marginTop: "30px", float: "right" }}>
							<Button type="submit">Cadastrar</Button>
						</div>
					</form>
				)}
			</Modal.Body>
			<Modal.Footer></Modal.Footer>
		</Modal>
	);
}
