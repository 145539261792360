import {
	NavLink,
	withRouter,
	Switch,
	Link,
	Route,
	RouteComponentProps,
} from "react-router-dom";
import React from "react";
import moment from "moment";
import { Nav, Navbar, NavDropdown, Modal, Button } from "react-bootstrap";
import logo from "../../assets/logo.svg";
import Plants from "../Plants";
import Reports from "../Reports";
import Settings from "../Settings";
import { logout } from "../../services/auth";
import { Provider, Subscribe } from "unstated";
import PlantContainer from "../../containers/PlantContainer";
import UserContainer from "../../containers/UserContainer";
import { getAuth, getRemember, setAuth } from "../../services/auth";
import api from "../../services/api";
import { PlantStruct, UserStruct } from "../../types/types";
import SingleTank from "../SingleTank";
import SingleSensor from "../SingleSensor";
import Roles from "../Roles";
import User from "../User";
import ChangePasswordLocal from "../ChangePasswordLocal";
import Users from "../Users";
import ReportDischarge from "../ReportDischarge";
import ReportConsolidated from "../ReportConsolidated";
import ReportPreviousStock from "../ReportPreviousStock";
import AppContainer from "../../containers/AppContainer";
import HirePlanModal from "../../components/HirePlanModal";

interface State {
	expanded: boolean;
	plantsRequested: Array<{
		id: string;
		name: string;
	}>;
}
type PathParamsType = {};
type PropsType = RouteComponentProps<PathParamsType> & {};

export class Dashboard extends React.Component<PropsType, State> {
	intervalId: number | undefined = undefined;
	container = new PlantContainer();
	userContainer = new UserContainer();
	appContainer = new AppContainer();
	timeoutPlantsRequested = -1;
	state = {
		expanded: false,
		plantsRequested: new Array<{
			id: string;
			name: string;
		}>(),
	};

	async fetchPlans() {
		try {
			let response = await api.get(`/plans?page=1&start=0&limit=-1`);
			let response2 = await api.get(
				"/users/me/plans?page=1&start=0&limit=-1"
			);
			let myPlants = response2.data;
			let plants = response.data.rows.map((item, index) => {
				const plantAliasSearched = myPlants.filter((item2) => {
					return item2.plan === item._id;
				});
				let plantAlias: any = null;
				if (plantAliasSearched.length > 0) {
					plantAlias = plantAliasSearched[0];
				}
				if (plantAlias != null) {
					return {
						id: item._id,
						name:
							plantAlias.alias !== undefined
								? plantAlias.alias
								: item.name,
						index: index,
						status: item.status,
						premium: item.premium ? item.premium : false,
						connected: item.reachable,
						userActions: plantAlias.actions,
						refresh: this.fetchPlantValue.bind(this),
						vlinks: [],
						users: [],
					};
				}
			});
			let plantsRequested = plants
				.filter((item) => {
					return item != null && item.status === 1;
				})
				.map((item) => {
					return {
						id: item.id,
						name: item.name,
					};
				});
			this.setState({
				plantsRequested,
			});
			plants = plants.filter((item) => {
				return item != null && item.status === 3;
			});

			for (let index = 0; index < plants.length; index++) {
				const plant = plants[index];
				let filter = [
					{
						operator: "eq",
						property: "plans.plan",
						value: plant.id,
					},
				];
				let responseUsers = await api.get(
					`/users?page=1&start=0&limit=25&filter=${encodeURI(
						JSON.stringify(filter)
					)}`
				);
				let users = responseUsers.data.rows as Array<UserStruct>;
				plant.users = users;
			}

			plants = plants.map((item, index) => {
				item.index = index;
				return item;
			});
			this.container.setState({ plants }, async () => {
				await this.fetchBarriers();
				await this.fetchValues();
				setTimeout(() => {
					this.container.setState({ loading: false });
				}, 1000);
				clearInterval(this.intervalId);
				this.intervalId = setInterval(this.startSync, 5000);
			});
		} catch (err) {
			//Ignore
		}
	}

	startSync = async () => {
		await this.isAuth();
		await this.fetchBarriers(true);
		await this.fetchValues();
	};

	async isAuth() {
		await api.get("/users/isAuthenticated");
	}

	async fetchBarriers(refresh = false) {
		const plants = this.container.state.plants;
		for (let index = 0; index < plants.length; index++) {
			const plant = plants[index] as PlantStruct;
			let response = await api.get(`/plans/${plant.id}/barriers`);
			let items = response.data.rows.filter((item) => {
				return item.deleted_at == null;
			});
			if (items.length != plant.vlinks.length) {
				refresh = false;
			}
			let barriers = items.map((item, index) => {
				return {
					id: item.id,
					voltage: item.fieldbus_voltage,
					name: item.name,
					index: index,
					status:
						item.status === 8 || item.status === 9
							? 0
							: item.status,
					gprs: item.gprs,
					amp: item.fieldbus_current,
					location: item.location,
					tanks: [],
					sensors: [],
					healthStatus: item.health_status,
				};
			});
			if (refresh) {
				for (let index2 = 0; index2 < barriers.length; index2++) {
					const vlinkRefreshed = barriers[index2];
					let vlinksFound: Array<String> = [];
					for (
						let index3 = 0;
						index3 < plant.vlinks.length;
						index3++
					) {
						const vlinkRef = plant.vlinks[index3];
						if (vlinkRef.id === vlinkRefreshed.id) {
							vlinksFound.push(vlinkRef.id);
							if (vlinkRef.voltage !== vlinkRefreshed.voltage) {
								vlinkRef.voltage = vlinkRefreshed.voltage;
							}
							if (vlinkRef.name !== vlinkRefreshed.name) {
								vlinkRef.name = vlinkRefreshed.name;
							}
							if (vlinkRef.amp !== vlinkRefreshed.amp) {
								vlinkRef.amp = vlinkRefreshed.amp;
							}
							if (vlinkRef.status !== vlinkRefreshed.status) {
								vlinkRef.status =
									vlinkRefreshed.status === 8 ||
									vlinkRefreshed.status === 9
										? 0
										: vlinkRefreshed.status;
							}
							if (
								JSON.stringify(vlinkRef.healthStatus) !==
								JSON.stringify(vlinkRefreshed.healthStatus)
							) {
								vlinkRef.healthStatus =
									vlinkRefreshed.healthStatus;
							}
						}
					}
					if (!vlinksFound.includes(vlinkRefreshed.id)) {
						plant.vlinks.push(vlinkRefreshed);
					}
				}
			} else {
				plant.vlinks = barriers;
			}

			this.container.setState((prevState) => ({
				plants: prevState.plants.map((obj) =>
					obj.id === plant.id ? Object.assign(obj, plant) : obj
				),
			}));
		}
	}

	async fetchUser() {
		try {
			let response = await api.get(`/users/me`);
			let auth = getAuth();
			auth.user = response.data;
			setAuth(JSON.stringify(auth));
		} catch (err) {
			alert(
				"Ocorreu um erro ao buscar informações, tente novamente mais tarde"
			);
		}
	}

	async fetchPlantValue(plant: PlantStruct) {
		try {
			let response = await api.get(`/plans/${plant.id}/devicesvalues`);
			plant.connected = response.data.plan.reachable;
			let plantSubData = response.data;
			plant.updatedAt = moment(response.data.updatedAt).toDate();
			let vlinksUpdatedTanks = new Array<string>();
			let vlinksUpdatedSensors = new Array<string>();
			for (
				let index2 = 0;
				index2 < plantSubData.indicators.length;
				index2++
			) {
				const indicator = plantSubData.indicators[index2];
				for (let vlink of plant.vlinks) {
					if (vlink.id == indicator.barrier) {
						if (!vlinksUpdatedTanks.includes(vlink.id)) {
							vlinksUpdatedTanks.push(vlink.id);
						}
						let found = false;
						let tankData = {
							id: indicator._id,
							status: indicator.status,
							statusDevice: indicator.statusDevice,
							errorCount: indicator.errorCount,
							alarmType: indicator.alarm_type,
							value: indicator.value,
							error: indicator.error,
							index: index2,
							fullVolume: indicator.full_volume,
							minVolume: indicator.min_volume,
							maxValue: indicator.maxValue,
							name: indicator.name,
							valuePercent: indicator.valuePercent,
							reason:
								indicator.last_reading &&
								indicator.last_reading.reason
									? indicator.last_reading.reason
									: undefined,
							liquidLevel: indicator.liquid_level,
							way: indicator.way,
							isVolume: indicator.hasVolume,
						};
						let indexTank = 0;
						for (let tank of vlink.tanks) {
							if (tank.id == indicator._id) {
								found = true;
								if (
									JSON.stringify(tankData) !==
									JSON.stringify(vlink.tanks[indexTank])
								) {
									vlink.tanks[indexTank] = tankData;
								}
							}
							indexTank++;
						}
						if (!found) {
							vlink.tanks.push(tankData);
						}
					}
				}
			}

			for (
				let index2 = 0;
				index2 < plantSubData.sensors.length;
				index2++
			) {
				const sensor = plantSubData.sensors[index2];
				for (let vlink of plant.vlinks) {
					if (vlink.id == sensor.barrier) {
						if (!vlinksUpdatedSensors.includes(vlink.id)) {
							vlinksUpdatedSensors.push(vlink.id);
						}
						let found = false;
						let sensorData = {
							id: sensor._id,
							index: index2,
							value: sensor.value,
							name: sensor.name,
							problem: sensor.problem,
							recognized: sensor.recognized,
							serialNumber: sensor.serial_number,
						};
						let indexSensor = 0;
						for (let sensorF of vlink.sensors) {
							if (sensorF.id == sensor._id) {
								found = true;
								if (
									JSON.stringify(sensorData) !==
									JSON.stringify(vlink.sensors[indexSensor])
								) {
									vlink.sensors[indexSensor] = sensorData;
								}
							}
							indexSensor++;
						}
						if (!found) {
							vlink.sensors.push(sensorData);
						}
					}
				}
			}
			var forceRefresh = false;
			for (let index = 0; index < plant.vlinks.length; index++) {
				if (!vlinksUpdatedTanks.includes(plant.vlinks[index].id)) {
					plant.vlinks[index].tanks = [];
				}
				if (!vlinksUpdatedSensors.includes(plant.vlinks[index].id)) {
					plant.vlinks[index].sensors = [];
				}
				if (
					plantSubData.indicators.filter(
						(t) => t.barrier == plant.vlinks[index].id
					).length !== plant.vlinks[index].tanks.length
				) {
					forceRefresh = true;
				}
				if (
					plantSubData.sensors.filter(
						(t) => t.barrier == plant.vlinks[index].id
					).length !== plant.vlinks[index].sensors.length
				) {
					forceRefresh = true;
				}
			}
			if (forceRefresh) {
				window.location.reload();
			}
			this.container.setState((prevState) => ({
				plants: prevState.plants.map((obj) =>
					obj.id === plant.id ? Object.assign(obj, plant) : obj
				),
			}));
		} catch (error) {
			plant.connected = false;
			this.container.setState((prevState) => ({
				plants: prevState.plants.map((obj) =>
					obj.id === plant.id ? Object.assign(obj, plant) : obj
				),
			}));
		}
	}

	async componentDidMount() {
		await this.checkLogin();
		await this.fetchUser();
		this.bindUser();
		let auth = getAuth();
		if (auth.user.role === "5bb6c21eb2ca821ed84b7c93") {
			this.props.history.replace("/configuracoes");
		} else {
			await this.fetchPlans();
		}
	}
	async checkLogin() {
		try {
			let remember = getRemember();
			const response = await api.post("/login", {
				email: remember.email,
				password: remember.password,
				autologin: true,
			});
		} catch (err) {}
	}

	async bindUser() {
		let auth = getAuth();

		this.userContainer.setState({
			user: {
				name: auth.user.name,
				id: auth.user._id,
				isAdmin: auth.user.role === "5bb6c21eb2ca821ed84b7c93",
			},
		});
	}

	async fetchValues() {
		const plants = this.container.state.plants;
		for (let index = 0; index < plants.length; index++) {
			const plant = plants[index] as PlantStruct;
			this.fetchPlantValue(plant);
		}
	}

	componentWillMount() {
		//@ts-ignore
		document
			.querySelector(":root")
			//@ts-ignore
			.style.setProperty("--vh", window.innerHeight / 100 + "px");
		window.addEventListener("resize", () => {
			if (document != null) {
				//@ts-ignore
				document
					.querySelector(":root")
					//@ts-ignore
					.style.setProperty("--vh", window.innerHeight / 100 + "px");
			}
		});
	}
	componentWillUnmount() {
		if (this.intervalId) {
			clearInterval(this.intervalId);
		}
	}

	onAcceptPlant = async () => {
		if (this.state.plantsRequested.length > 0) {
			let plants = this.state.plantsRequested;
			let plant = plants[0];
			try {
				await api.patch(`plans/${plant.id}`, {
					status: 3,
				});
				plants.shift();
				this.setState({
					plantsRequested: plants,
				});
				window.location.reload();
			} catch (err) {
				alert("Tente novamente");
			}
		}
	};

	onDenyPlant = async () => {
		if (this.state.plantsRequested.length > 0) {
			let plants = this.state.plantsRequested;
			let plant = plants[0];
			try {
				await api.patch(`plans/${plant.id}`, {
					status: 2,
				});
				plants.shift();
				this.setState({
					plantsRequested: plants,
				});
				window.location.reload();
			} catch (err) {
				alert("Tente novamente");
			}
		}
	};

	render() {
		return (
			<Provider
				inject={[this.container, this.userContainer, this.appContainer]}
			>
				<Modal
					show={this.state.plantsRequested.length > 0}
					backdrop="static"
					keyboard={false}
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<Modal.Header>
						<Modal.Title id="contained-modal-title-vcenter">
							Nova planta
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{this.state.plantsRequested.length > 0 && (
							<p>
								Uma nova planta com nome{" "}
								{this.state.plantsRequested[0].name} foi
								associada a sua conta, deseja aceitar?
							</p>
						)}
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="secondary"
							onClick={() => {
								this.setState({
									plantsRequested: [],
								});
								clearTimeout(this.timeoutPlantsRequested);
								this.timeoutPlantsRequested = setTimeout(
									async () => {
										await this.fetchPlans();
									},
									1000 * 60 * 30
								);
							}}
						>
							Perguntar Depois
						</Button>
						<Button onClick={this.onDenyPlant} variant="warning">
							Não
						</Button>
						<Button onClick={this.onAcceptPlant} variant="primary">
							Sim
						</Button>
					</Modal.Footer>
				</Modal>
				<Subscribe to={[UserContainer, AppContainer]}>
					{(userContainer, appContainer) => (
						<div>
							{appContainer.state.showPremium && (
								<HirePlanModal
									show={appContainer.state.showPremium}
									onHide={() => {
										appContainer.setState({
											showPremium: false,
										});
									}}
								/>
							)}
							<Navbar
								expanded={this.state.expanded}
								bg="dark"
								className="custom-nav"
								variant="dark"
								expand="lg"
							>
								<Navbar.Toggle
									onClick={() =>
										this.setState({
											expanded: !this.state.expanded,
										})
									}
									aria-controls="basic-navbar-nav"
								/>
								<Navbar.Brand href="/">
									<img
										src={logo}
										width="50"
										height="50"
										className="d-inline-block align-top"
										alt="SIVWEB"
									/>
								</Navbar.Brand>
								<Navbar.Collapse id="basic-navbar-nav">
									<Nav className="mr-auto">
										{!userContainer.state.user.isAdmin && (
											<NavLink
												activeClassName="active"
												onClick={() =>
													this.setState({
														expanded: false,
													})
												}
												className="nav-link"
												to="/dashboard"
											>
												Home
											</NavLink>
										)}
										{!userContainer.state.user.isAdmin && (
											<NavLink
												onClick={() =>
													this.setState({
														expanded: false,
													})
												}
												activeClassName="active"
												className="nav-link"
												to="/relatorios"
											>
												Relatórios
											</NavLink>
										)}
										{userContainer.state.user.isAdmin && (
											<NavLink
												onClick={() =>
													this.setState({
														expanded: false,
													})
												}
												activeClassName="active"
												className="nav-link"
												to="/configuracoes"
											>
												Configurações
											</NavLink>
										)}
									</Nav>
									<Nav>
										<NavDropdown
											title={
												userContainer.state.user
													? userContainer.state.user.name.toUpperCase()
													: ""
											}
											id="collasible-nav-dropdown"
										>
											<Link
												className="dropdown-item"
												onClick={() =>
													this.setState({
														expanded: false,
													})
												}
												role="button"
												to="/configuracoes/usuario"
											>
												Meu Perfil
											</Link>
											<NavDropdown.Item
												target="_blank"
												rel="noopener"
												href="http://nkl.com.br/wa_upload/image/Manual_SIVWEB.pdf"
											>
												Documentação SIV Web
											</NavDropdown.Item>

											<NavDropdown.Item
												target="_blank"
												rel="noopener"
												href="http://nkl.com.br/wa_upload/image/Manual_NKLSIV_4.pdf"
											>
												Documentação SIV Local
											</NavDropdown.Item>
											<NavDropdown.Item
												target="_blank"
												rel="noopener"
												href="http://nkl.com.br/wa_upload/image/Termos_SIV.pdf"
											>
												Termos
											</NavDropdown.Item>

											<NavDropdown.Item
												onClick={() => {
													logout();
													this.props.history.replace(
														"/"
													);
												}}
												href="#"
											>
												Sair do SIV
											</NavDropdown.Item>
										</NavDropdown>
									</Nav>
								</Navbar.Collapse>
							</Navbar>
							<div
								style={{
									paddingLeft: 0,
									paddingRight: 0,
								}}
								className="container-fluid main-scroll"
							>
								<Switch>
									<Route exact path={`/dashboard`}>
										<Plants />
									</Route>
									<Route
										path={`/plant/:plantId/barriers/:barrierId/devices/:deviceId`}
									>
										<SingleTank />
									</Route>
									<Route
										path={`/plant/:plantId/barriers/:barrierId/sensors/:deviceId`}
									>
										<SingleSensor />
									</Route>
									<Route exact path={`/relatorios`}>
										<Reports />
									</Route>
									<Route path={`/relatorios/consolidado`}>
										<ReportConsolidated />
									</Route>
									<Route path={`/relatorios/entrada-estoque`}>
										<ReportDischarge />
									</Route>
									<Route
										path={`/relatorios/estoque-anterior`}
									>
										<ReportPreviousStock />
									</Route>
									<Route exact path={`/configuracoes`}>
										<Settings />
									</Route>
									<Route path="/configuracoes/papeis">
										<Roles />
									</Route>
									<Route path="/usuarios">
										<Users />
									</Route>
									<Route path="/configuracoes/usuario">
										<User />
									</Route>
									<Route path="/configuracoes/alterar-senha-local">
										<ChangePasswordLocal />
									</Route>
								</Switch>
							</div>
						</div>
					)}
				</Subscribe>
			</Provider>
		);
	}
}

export default withRouter(Dashboard);
