import React, { Component } from "react";
import styled from "styled-components";
import { Button, Modal } from "react-bootstrap";
import tank from "../../assets/tank.svg";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import api from "../../services/api";
import pdfMake from "pdfmake/build/pdfmake";
import { vfs } from "../../utils/vfs_fonts";
import { getAuth } from "../../services/auth";
import Numeral from "numeral";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { exportCSVFile } from "../../utils/export";

const Container = styled.div`
	padding: 20px;
`;

const ContainerRound = styled.div`
	min-height: 90px;
	width: 100%;
	padding: 20px;
	background: #3a3848 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 15px #00000029;
	border-radius: 10px;
`;

export class ReportPreviousStock extends Component {
	state = {
		dateStart: moment().subtract(1, "day").toDate(),
		plants: new Array<{ value: string; label: string }>(),
		selectedPlants: new Array<any>(),
		times: new Array<string>(
			moment().format("HH:mm:ss"),
			moment().format("HH:mm:ss"),
			moment().format("HH:mm:ss")
		),
		generating: false,
	};

	async componentDidMount() {
		let times = window.localStorage.getItem("@sivweb_TIMES");
		if (times != null && times !== "") {
			try {
				let timesObj = JSON.parse(times);
				this.setState({
					times: timesObj,
				});
			} catch (err) {
				//Ignore
			}
		}
		let response = await api.get("/plans/getUserAllPremiums");
		let items: Array<{ label: string; value: string }> = response.data.map(
			(item) => {
				return {
					value: item._id,
					label: item.name,
				};
			}
		);
		this.setState({
			plants: items,
		});
	}

	onSubmit = async (event) => {
		event.preventDefault();
	};

	generateData = async (type: number) => {
		if (this.state.selectedPlants.length === 0) {
			alert("Selecione ao menos uma planta");
			return;
		}
		try {
			this.setState({
				generating: true,
			});
			let date = moment(this.state.dateStart)
				.startOf("day")
				.utc()
				.format("YYYY-MM-DD");
			let response = await api.post("/plans/report-previous-stock", {
				plans: this.state.selectedPlants.map((item) => {
					return item.value;
				}),
				times: this.state.times.map((item) => {
					return moment(`${date} ${item}`).format("HH:mm:ss Z");
				}),
				date,
			});
			if (type == 1) {
				this.generatePDF(response.data);
			} else {
				this.generateCSV(response.data);
			}
		} catch (e) {
		} finally {
			this.setState({
				generating: false,
			});
		}
	};

	getStatus(value, reason) {
		if (value >= 0) {
			return `Estoque(${Numeral(value).format("0,0")}L)`;
		} else {
			if (reason == 4) {
				return "Falha de Hardware";
			} else if (reason == 6) {
				return "Indisponível";
			}
			return "Indisponível";
		}
	}

	generatePDF(data: any) {
		let email = getAuth().user.email;
		pdfMake.vfs = vfs;
		pdfMake.fonts = {
			Roboto: {
				normal: "Roboto-Regular.ttf",
				bold: "Roboto-Bold.ttf",
				italics: "Roboto-Italic.ttf",
				bolditalics: "Roboto-BoldItalic.ttf",
			},
		};

		let content = data.map((item, index) => {
			let rowsTanks = item.data.map((row) => {
				return [
					row.data.device,
					`${moment
						.utc(row.data.dateTime)
						.local()
						.format("HH:mm")} - ${this.getStatus(
						row.data.value,
						row.data.reason
					)}`,
				];
			});
			return [
				{
					margin: [0, index > 0 ? 40 : 0, 0, 0],
					text: `${item.plant} - ${moment().format(
						"DD/MM/YYYY HH:mm"
					)}`,
					style: "header",
				},
				{
					text: `Usuário: ${email}`,
					style: "header",
					margin: [0, 5, 0, 0],
				},
				{
					text: `ESTOQUE ANTERIOR - ${moment(
						this.state.dateStart
					).format("DD/MM/YYYY")}`,
					style: "header",
					margin: [0, 20, 0, 0],
				},
				{
					layout: "noBorders",
					margin: [0, 20, 0, 0],
					table: {
						headerRows: 1,
						widths: ["*", "*"],

						body: [
							[
								{ text: "Produto:", bold: true },
								{ text: "Estoque:", bold: true },
							],
							...rowsTanks,
						],
					},
				},
			];
		});

		var docDefinition = {
			info: {
				title: "Relatório Estoque Anterior",
			},
			content: content,
			defaultStyle: {
				font: "Roboto",
				fontSize: 8,
			},
			styles: {
				header: {
					fontSize: 12,
					bold: true,
				},
			},
		};
		try {
			pdfMake
				.createPdf(docDefinition)
				.download("relatorio-estoque-anterior.pdf");
		} catch (err) {
			console.error(err);
		}
	}

	generateCSV(data: any) {
		let items = new Array<{
			plant: string;
			device: string;
			dateTime: string;
			stock: string;
		}>();
		for (let index = 0; index < data.length; index++) {
			const itemA = data[index];
			items.push(
				...itemA.data.map((itemB) => {
					return {
						plant: itemA.plant,
						device: itemB.data.device,
						dateTime: moment
							.utc(itemB.data.dateTime)
							.local()
							.format("DD/MM/YYYY HH:mm"),
						stock: this.getStatus(
							itemB.data.value,
							itemB.data.reason
						),
					};
				})
			);
		}
		var headersCSV = {
			plant: "PLANTA",
			device: "PRODUTO",
			dateTime: "DATA/HORA",
			stock: "ESTOQUE EM LITROS",
		};
		exportCSVFile(headersCSV, items, "relatorio-estoque-anterior");
	}

	*renderTimes() {
		for (let index = 0; index < this.state.times.length; index++) {
			yield (
				<input
					value={this.state.times[index]}
					className="mt-2"
					type="time"
					onChange={(event) => {
						let newTimes = this.state.times;
						newTimes[index] = event.target.value;
						this.setState({ times: newTimes }, () => {
							this.updateLocalStorage();
						});
					}}
					name={`time-${index}`}
				/>
			);
		}
	}

	updateLocalStorage() {
		window.localStorage.setItem(
			"@sivweb_TIMES",
			JSON.stringify(this.state.times)
		);
	}

	render() {
		return (
			<div>
				<Modal
					show={this.state.generating}
					onHide={() => {
						this.setState({
							generating: false,
						});
					}}
				>
					<Modal.Header>
						<Modal.Title>Aguarde...</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p className="text-center">
							<FontAwesomeIcon
								inverse
								size="2x"
								icon={faSync}
								spin
								className="mr-3"
							/>{" "}
							Gerando Relatório
						</p>
					</Modal.Body>
				</Modal>
				<Container>
					<ContainerRound className="d-flex align-items-center justify-content-between">
						<div className="d-flex align-items-center">
							<img alt="Tanque" height="32" src={tank} />
							<h2 className="ml-3 mb-0">
								Relatório de Estoque Anterior
							</h2>
						</div>
					</ContainerRound>
					<ContainerRound
						className="p-2 d-flex"
						style={{ marginTop: 20 }}
					>
						<form onSubmit={this.onSubmit} className="w-75 m-auto">
							<div className="row">
								<div className="col-lg mt-3">
									<label className="mr-2">Plantas</label>
									<Select
										className="fg-black"
										placeholder="Selecione uma planta"
										noOptionsMessage={() =>
											"Sem plantas selecionadas"
										}
										isMulti={true}
										value={this.state.selectedPlants}
										onChange={(selected) => {
											this.setState({
												selectedPlants: selected,
											});
										}}
										options={this.state.plants}
									/>
								</div>
								<div className="col-lg mt-4 mt-md-3">
									<div className="row">
										<div className="col-md">
											<label
												className="ml-3"
												htmlFor="dateStart"
											>
												Data
											</label>
											<DatePicker
												customInput={
													<input
														className="form-control ml-2"
														type="text"
														name="dateStart"
													/>
												}
												locale="pt-BR"
												dateFormat="dd/MM/yyyy"
												selected={this.state.dateStart}
												onChange={(date) => {
													this.setState({
														dateStart: moment(
															date!
														).toDate(),
													});
												}}
											/>
										</div>
										<div className="col-md mt-3 mt-md-0">
											<label className="ml-3">
												Horas:
											</label>
											<div className="ml-2">
												<div className="d-flex flex-column">
													{Array.from(
														this.renderTimes()
													)}
												</div>
											</div>
										</div>
										<div className="col">
											<Button
												className="mt-2 ml-2"
												size="sm"
												variant="info"
												onClick={() => {
													let newTimes = this.state
														.times;
													newTimes.push(
														moment().format(
															"HH:mm:ss"
														)
													);
													this.setState(
														{
															times: newTimes,
														},
														() => {
															this.updateLocalStorage();
														}
													);
												}}
											>
												Adicionar Hora
											</Button>
											<Button
												className="mt-2 ml-2"
												size="sm"
												variant="warning"
												onClick={() => {
													if (
														this.state.times
															.length > 1
													) {
														let newTimes = this
															.state.times;
														newTimes.pop();
														this.setState(
															{
																times: newTimes,
															},
															() => {
																this.updateLocalStorage();
															}
														);
													}
												}}
											>
												Remover Hora
											</Button>
										</div>
									</div>
								</div>
								<div className="col-lg mt-4">
									<Button
										className="ml-lg-3 mt-lg-4"
										onClick={() => this.generateData(1)}
										type="button"
									>
										Gerar PDF
									</Button>
									<Button
										className="ml-3 mt-lg-4"
										onClick={() => this.generateData(2)}
										variant="secondary"
										type="button"
									>
										Gerar CSV
									</Button>
								</div>
							</div>
						</form>
					</ContainerRound>
				</Container>
			</div>
		);
	}
}

export default ReportPreviousStock;
