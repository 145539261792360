export const convertToCSV = objArray => {
	var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
	var str = "";
	for (var i = 0; i < array.length; i++) {
		var line = "";
		for (var index in array[i]) {
			if (line != "") line += ",";

			line += array[i][index];
		}

		str += line + "\r\n";
	}
	return str;
};

export const exportCSVFile = (headers, items, fileTitle) => {
	if (headers) {
		items.unshift(headers);
	}
	var jsonObject = JSON.stringify(items);

	var csv = convertToCSV(jsonObject);

	// Add BOM
	csv = "\ufeff" + csv

	var exportedFilenmae = fileTitle + ".csv" || "data.csv";

	var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
	//@ts-ignore
	if (navigator.msSaveBlob) {
		// IE 10+
		//@ts-ignore
		navigator.msSaveBlob(blob, exportedFilenmae);
	} else {
		var link = document.createElement("a");
		if (link.download !== undefined) {
			// feature detection
			// Browsers that support HTML5 download attribute
			var url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", exportedFilenmae);
			link.style.visibility = "hidden";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
};
