import React, { useState } from "react";
import styled from "styled-components";
import lock from "../../assets/lock.svg";
import useForm from "react-hook-form";
import { Button } from "react-bootstrap";
import api from "../../services/api";
const Container = styled.div`
	padding: 20px;
`;

const ContainerRound = styled.div`
	min-height: 90px;
	width: 100%;
	padding: 20px;
	background: #3a3848 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 15px #00000029;
	border-radius: 10px;
`;

function ChangePasswordLocal() {
	const [token, setToken] = useState("");
	const [tokenGenerated, setTokenGenerated] = useState("");
	const { handleSubmit } = useForm();
	const onSubmit = async () => {
		try {
			let response = await api.post("local/support/reset-password", {
				token
			});
			setTokenGenerated(response.data);
		} catch (error) {}
	};

	const copyValue = () => {
		var dummy = document.createElement("textarea");
		document.body.appendChild(dummy);
		dummy.value = tokenGenerated;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
	};
	return (
		<Container>
			<ContainerRound className="d-flex align-items-center justify-content-between">
				<div className="d-flex align-items-center">
					<img height="32" src={lock} />
					<h2 className="ml-3 mb-0">Troca de senha local</h2>
				</div>
			</ContainerRound>
			<ContainerRound
				className="p-4 d-flex flex-wrap flex-column"
				style={{ marginTop: 20 }}
			>
				<form onSubmit={handleSubmit(onSubmit)} className="w-50">
					<div className="form-group">
						<label htmlFor="token">Token</label>
						<input
							id="token"
							value={token}
							name="token"
							onChange={item => {
								setToken(item.target.value);
							}}
							placeholder="Token"
							type="text"
							className="form-control"
						/>
					</div>

					<div className="form-group row">
						<div className="col-12 d-flex justify-content-center">
							<Button type="submit">Gerar Token</Button>
						</div>
					</div>
				</form>
				{tokenGenerated != "" && (
					<div className="d-flex flex-row">
						<span>Token Gerado: {tokenGenerated}</span>
						<Button onClick={copyValue} className="ml-4">
							Copiar
						</Button>
					</div>
				)}
			</ContainerRound>
		</Container>
	);
}

export default ChangePasswordLocal;
