import React, { useState } from "react";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import useForm from "react-hook-form";
import moment, { Moment } from "moment";
import api from "../../services/api";
import Button from "react-bootstrap/Button";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt";
interface Props extends ModalProps {
	type: Number;
	plantId: string;
	deviceId: string;
	onComplete: (success) => void;
}

export default function DeleteModal(props: Props) {
	registerLocale("pt-BR", pt);
	const [dateStart, setDateStart] = useState(
		moment()
			.subtract(3, "days")
			.startOf("day")
			.toDate()
	);
	const [dateEnd, setDateEnd] = useState(
		moment()
			.subtract(1, "days")
			.endOf("day")
			.toDate()
	);
	const { handleSubmit, reset } = useForm();
	const [error, setError] = useState("");
	let { onComplete } = props;
	const onSubmit = async data => {
		try {
			let dateStartStr = moment(dateStart)
				.startOf("day")
				.format("YYYY-MM-DD HH:mm:ss");
			let dateEndStr = moment(dateEnd)
				.endOf("day")
				.format("YYYY-MM-DD HH:mm:ss");
			if (props.type === 1) {
				await api.post(
					`/local/sensor_logs/${props.plantId}/${props.deviceId}/delete`,
					{
						start: dateStartStr,
						end: dateEndStr
					}
				);
			} else {
				await api.post(
					`/local/readings/${props.plantId}/device/${props.deviceId}/delete`,
					{
						start: dateStartStr,
						end: dateEndStr
					}
				);
			}
			if (onComplete != null) {
				onComplete(true);
				reset();
			}
		} catch (err) {}
	};
	return (
		<Modal
			{...props}
			size="sm"
			onHide={() => {
				onComplete(false);
			}}
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Apagar Registros
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form onSubmit={handleSubmit(onSubmit)}>
					{error && (
						<div className="alert alert-danger" role="alert">
							{error}
						</div>
					)}
					<div className="d-flex mt-md-0 mt-3 align-items-center">
						<label className="m-0">De</label>
						<DatePicker
							locale="pt-BR"
							dateFormat="dd/MM/yyyy"
							customInput={
								<input
									className="form-control ml-2"
									type="text"
								/>
							}
							selected={dateStart}
							onChange={date => {
								setDateStart(date!);
							}}
						/>
						<label className="ml-4">Até</label>
						<DatePicker
							locale="pt-BR"
							dateFormat="dd/MM/yyyy"
							customInput={
								<input
									className="form-control ml-2"
									type="text"
								/>
							}
							selected={dateEnd}
							onChange={date => {
								setDateEnd(date!);
							}}
						/>
					</div>
					<div style={{ marginTop: "30px", float: "right" }}>
						<Button
							variant="warning"
							onClick={() => {
								if (onComplete != null) {
									onComplete(false);
								}
							}}
							type="button"
						>
							Cancelar
						</Button>
						<Button className="ml-2" type="submit">
							Confirmar
						</Button>
					</div>
				</form>
			</Modal.Body>
			<Modal.Footer></Modal.Footer>
		</Modal>
	);
}
