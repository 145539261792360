export const TOKEN_KEY = "@sivweb-Token";
export const STORAGE_KEY = "@sivweb-Storage";
export const REMEMBER_KEY = "@sivweb-remember";

const stringToBoolean = function (string) {
	switch (string.toLowerCase().trim()) {
		case "true":
		case "yes":
		case "1":
			return true;
		case "false":
		case "no":
		case "0":
		case null:
			return false;
		default:
			return Boolean(string);
	}
};
export const isAuthenticated = () => {
	if (localStorage.getItem(STORAGE_KEY) != null) {
		let check = stringToBoolean(localStorage.getItem(STORAGE_KEY));
		if (check) {
			return (
				localStorage.getItem(TOKEN_KEY) !== null &&
				localStorage.getItem(REMEMBER_KEY) !== null
			);
		}
	}
	return (
		sessionStorage.getItem(TOKEN_KEY) !== null &&
		sessionStorage.getItem(REMEMBER_KEY) !== null
	);
};
export const getToken = () => {
	if (localStorage.getItem(STORAGE_KEY) != null) {
		let check = stringToBoolean(localStorage.getItem(STORAGE_KEY));
		if (check) {
			let item = localStorage.getItem(TOKEN_KEY);
			if (item != null) {
				return JSON.parse(item).authorization;
			}
			return null;
		}
	}
	let item = sessionStorage.getItem(TOKEN_KEY);
	if (item != null) {
		return JSON.parse(item).authorization;
	}
	return null;
};
export const login = (auth, email, password, remember) => {
	localStorage.setItem(STORAGE_KEY, remember);
	if (remember) {
		localStorage.setItem(TOKEN_KEY, auth);
		localStorage.setItem(REMEMBER_KEY, JSON.stringify({ email, password }));
	} else {
		sessionStorage.setItem(TOKEN_KEY, auth);
		sessionStorage.setItem(REMEMBER_KEY, JSON.stringify({ email, password }));
	}
};

export const getAuth = () => {
	if (localStorage.getItem(STORAGE_KEY) != null) {
		let check = stringToBoolean(localStorage.getItem(STORAGE_KEY));
		if (check) {
			const token = localStorage.getItem(TOKEN_KEY);
			return token ? JSON.parse(token) : null;
		}
	}
	const token = sessionStorage.getItem(TOKEN_KEY);
	return token !== null ? JSON.parse(token) : null;
};

export const getRemember = () => {
	if (localStorage.getItem(STORAGE_KEY) != null) {
		let check = stringToBoolean(localStorage.getItem(STORAGE_KEY));
		if (check) {
			const remember = localStorage.getItem(REMEMBER_KEY);
			return remember !== null ? JSON.parse(remember) : null;
		}
	}
	const remember = sessionStorage.getItem(REMEMBER_KEY);
	return remember !== null ? JSON.parse(remember) : null;
};

export const setAuth = (auth) => {
	if (localStorage.getItem(STORAGE_KEY) != null) {
		let check = stringToBoolean(localStorage.getItem(STORAGE_KEY));
		if (check) {
			localStorage.setItem(TOKEN_KEY, auth);
			sessionStorage.removeItem(TOKEN_KEY);
		} else {
			sessionStorage.setItem(TOKEN_KEY, auth);
			localStorage.removeItem(TOKEN_KEY);
		}
	} else {
		sessionStorage.setItem(TOKEN_KEY, auth);
	}
};

export const logout = () => {
	sessionStorage.removeItem(TOKEN_KEY);
	sessionStorage.removeItem(REMEMBER_KEY);
	localStorage.removeItem(TOKEN_KEY);
	localStorage.removeItem(REMEMBER_KEY);
};
