import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Sensor from "../Sensor";
import { SensorStruct, PlantStruct } from "../../types/types";

interface Props {
	sensors: Array<SensorStruct>;
	plantIndex: Number;
	vlinkIndex: Number;
	premium: Boolean;
	connected: Boolean;
	plant: PlantStruct;
}

const Title = styled.span`
	color: #fff;
	font-weight: bold;
	font-size: 14px;
`;
const Container = styled.div`
	padding: 10px;
	background-color: #32303a;
	border-radius: 10px;
`;

const CustomButton = styled(Button)`
	padding: 1px 5px;
`;
function Sensors(props: Props) {
	const [expanded, setExpanded] = useState(false);
	const toggle = () => {
		setExpanded(!expanded);
	};

	const renderSensors = function* () {
		for (let index = 0; index < props.sensors.length; index++) {
			const sensor = props.sensors[index];
			yield (
				<Sensor
					premium={props.premium}
					updatedAt={props.plant.updatedAt}
					vlinkIndex={props.vlinkIndex}
					plantIndex={props.plantIndex}
					key={sensor.id}
					connected={props.connected && props.plant.connected}
					expanded={expanded}
					data={sensor}
				/>
			);
		}
	};
	const ListSensors = () => {
		return (
			<div
				className={!expanded ? "d-flex flex-wrap" : ""}
				style={{ marginTop: expanded ? 10 : 0 }}
			>
				{Array.from(renderSensors())}
			</div>
		);
	};
	const ToggleButton = React.memo(() => {
		return (
			<CustomButton
				onClick={toggle}
				size="sm"
				className="ml-2"
				variant="secondary"
			>
				<FontAwesomeIcon icon={faChevronDown} />
			</CustomButton>
		);
	});
	return (
		<Container className="justify-content-between">
			<div className="d-flex justify-content-between">
				<Title>Sensores</Title>
				{!expanded ? (
					<div className="d-flex justify-content-between">
						<ListSensors />
						<ToggleButton />
					</div>
				) : (
					<ToggleButton />
				)}
			</div>
			{expanded ? <ListSensors /> : null}
		</Container>
	);
}

export default Sensors;
