import React from "react";
import { PlantStruct, RoleStruct, UserStruct } from "../../types/types";
import api from "../../services/api";
import ChangePlantNameModal from "../ChangePlantNameModal";
import AddUserModal from "../AddUserModal";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";

interface Props {
	back: Function;
	data: PlantStruct;
}

interface UserStructSettings extends UserStruct {
	roleName: String;
	deletable: Boolean;
}

interface State {
	roles: Array<RoleStruct>;
	modalShow: Boolean;
	userDisassociation?: UserStructSettings | null;
	showUserModal: Boolean;
	showPlantNameModal: Boolean;
	users: Array<UserStructSettings>;
	error: String;
}

export class PlantSettings extends React.PureComponent<Props, State> {
	state = {
		roles: new Array<RoleStruct>(),
		modalShow: false,
		userDisassociation: null,
		showUserModal: false,
		showPlantNameModal: false,
		users: [],
		error: "",
	};

	async fetchRoles() {
		try {
			let response = await api.get("/roles?page=1&start=0&limit=25");
			let roles = response.data.rows as Array<RoleStruct>;
			this.setState({ roles });
		} catch (err) {
			console.error(err);
		}
	}
	async componentDidMount() {
		await this.fetchRoles();
		await this.fetchUsers();
	}

	async fetchUsers() {
		try {
			let filter = [
				{
					operator: "eq",
					property: "plans.plan",
					value: this.props.data.id,
				},
			];
			let responseUsers = await api.get(
				`/users?page=1&start=0&limit=25&filter=${encodeURI(
					JSON.stringify(filter)
				)}`
			);
			let users = responseUsers.data.rows as Array<UserStructSettings>;
			for (let index = 0; index < users.length; index++) {
				const user = users[index];
				const plan = user.plans.filter((item) => {
					return item.plan == this.props.data.id;
				})[0];
				let role = this.state.roles.filter((item) => {
					return item._id == plan.role;
				})[0];
				user.roleName = role.name;
				user.deletable = !(
					role._id === "5bb6c21eb2ca821ed84b7c94" ||
					role._id === "5bb6c21eb2ca821ed84b7c93"
				);
			}
			this.setState({ users });
		} catch (err) {
			console.error(err);
		}
	}

	async unlinkUser() {
		try {
			let user = (this.state
				.userDisassociation as unknown) as UserStructSettings;
			await api.post(`/plans/${this.props.data.id}/unlink-user`, {
				user: user._id,
			});
			this.setState({ userDisassociation: undefined });
			this.fetchUsers();
		} catch (err) {
			const error = err as any;
			this.setState({ userDisassociation: undefined }, () => {
				if (error.response != null) {
					this.setState({ error: error.response.data });
				} else {
					this.setState({
						error: "Verifique sua conexão com a internet",
					});
				}
			});
		}
	}

	*renderUsers() {
		for (let index = 0; index < this.state.users!.length; index++) {
			const user = this.state.users![index] as UserStructSettings;
			yield (
				<div key={user._id}>
					<div className="row align-middle">
						<div className="col-sm font-weight-bold text-truncate">
							{user.name}
						</div>
						<div className="col-sm">{user.roleName}</div>

						<div className="col-xs">
							{user.deletable && (
								<Button
									onClick={() => {
										this.setState({
											userDisassociation: user,
											showUserModal: true,
										});
									}}
									variant="outline-danger"
									size="sm"
								>
									<FontAwesomeIcon icon={faTrash} />
								</Button>
							)}
						</div>
					</div>
					<hr />
				</div>
			);
		}
	}

	render() {
		return (
			<div
				className="p-3 fg-white d-flex flex-column"
				style={{ height: "calc(100% - 80px)" }}
			>
				<button
					onClick={() => {
						this.props.back();
					}}
					className="btn btn-block btn-secondary"
				>
					Voltar
				</button>
				<h4 className="mt-3">Configurações da Planta</h4>
				<div>
					<div className="row p-2">
						<div className="col-xs font-weight-bold">
							Nome de Exibição:
						</div>
						<div className="col-sm">{this.props.data.name}</div>
						<div className="col-xs">
							<Button
								onClick={() => {
									this.setState({
										showPlantNameModal: true,
									});
								}}
								variant="outline-primary"
								size="sm"
							>
								<FontAwesomeIcon icon={faEdit} /> Alterar
							</Button>
						</div>
					</div>
					<div className="row mt-5">
						<div className="col-sm font-weight-bold">Usuários:</div>
					</div>
					<div
						className="mt-2 p-3"
						style={{ maxHeight: 300, overflowY: "auto" }}
					>
						{Array.from(this.renderUsers())}
					</div>
				</div>
				<button
					onClick={() => {
						this.setState({
							modalShow: true,
						});
					}}
					className="btn btn-block btn-primary mt-auto"
				>
					Adicionar
				</button>
				<ChangePlantNameModal
					data={this.props.data}
					plantid={this.props.data.id}
					show={this.state.showPlantNameModal}
					onHide={async () => {
						this.setState({ showPlantNameModal: false });
						await this.fetchUsers();
					}}
					plantname={this.props.data.name}
				/>
				<AddUserModal
					show={this.state.modalShow}
					roles={this.state.roles}
					data={this.props.data}
					onHide={async () => {
						this.setState({ modalShow: false });
						await this.fetchUsers();
					}}
				/>
				<Modal show={this.state.error != ""}>
					<Modal.Header closeButton>
						<Modal.Title>Atenção</Modal.Title>
					</Modal.Header>

					<Modal.Body>{this.state.error}</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								this.setState({ error: "" });
							}}
							variant="primary"
						>
							OK
						</Button>
					</Modal.Footer>
				</Modal>
				{this.state.userDisassociation != null && (
					<Modal show={this.state.showUserModal}>
						<Modal.Header closeButton>
							<Modal.Title>Remover Usuário</Modal.Title>
						</Modal.Header>

						<Modal.Body>
							<p>
								Tem certeza que seja remover o usuário{" "}
								{
									((this.state
										.userDisassociation as unknown) as UserStructSettings)
										.name
								}{" "}
								da planta?
							</p>
						</Modal.Body>
						<Modal.Footer>
							<Button
								onClick={() => {
									this.setState(
										{ showUserModal: false },
										() => {
											this.setState({
												userDisassociation: null,
											});
										}
									);
								}}
								variant="secondary"
							>
								Não
							</Button>
							<Button
								onClick={() => {
									this.unlinkUser();
								}}
								variant="primary"
							>
								Sim
							</Button>
						</Modal.Footer>
					</Modal>
				)}
			</div>
		);
	}
}

export default PlantSettings;
