import React from "react";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

interface Props extends ModalProps {}
function HirePlanModal(props: Props) {
	const { onHide } = props;
	return (
		<Modal
			{...props}
			size="sm"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Atenção!
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					É necessário contratar um plano para visualizar mais
					detalhes.
				</p>
				<p>Entre em nosso site e entre em contato conosco</p>
				<a
					target="_blank"
					rel="noopener"
					className="btn btn-primary"
					href="https://loja.nkl.com.br/indicadores-de-volume/"
				>
					Contratar Plano
				</a>
			</Modal.Body>
			<Modal.Footer>
				<Button
					onClick={() => {
						if (onHide) onHide();
					}}
					variant="primary"
				>
					OK
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default HirePlanModal;
