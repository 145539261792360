import { Container } from "unstated";

interface UserState {
	user: {
		name: String;
		id: String;
		isAdmin: Boolean;
	};
}

class UserContainer extends Container<UserState> {
	state = {
		user: {
			name: "",
			id: "",
			isAdmin: false
		}
	};

	setName(value) {
		this.setState({
			user: {
				id: this.state.user.id,
				name: value,
				isAdmin: false
			}
		});
	}
}

export default UserContainer;
