import React, { Component } from "react";
import styled from "styled-components";
import plantaGprs from "../../assets/planta-gprs.png";
import plantaLocal from "../../assets/planta-local.png";
import plantaVe from "../../assets/VE.png";
import plantaVa from "../../assets/VA.png";
import surfaceOff from "../../assets/surface1.svg";
import refresh from "../../assets/refresh-cw.svg";
import more from "../../assets/more-vertical.svg";
import { Dropdown, Button } from "react-bootstrap";
import VLink from "../VLink";
import { PlantStruct } from "../../types/types";
import moment from "moment";
import PlantSettings from "../PlantSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faSync,
	faCog,
	faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { getAuth } from "../../services/auth";
import MapModal from "../../components/MapModal";

type PlantStyleProps = {
	connected: Boolean;
	loading: String;
};

const getBorder = (connected, loading) => {
	if (connected) {
		return "transparent";
	} else if (loading == "true") {
		return "transparent";
	} else {
		return "#FF0303";
	}
};

const Container = styled.div`
	width: 93%;
	margin-left: 14px;
	margin-right: 20px;
	min-height: 100px;
	background: #3a3848 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 15px #00000029;
	border-radius: 10px;
	opacity: 1;
	flex-shrink: 0;
	border: 2px solid ${(p: PlantStyleProps) => getBorder(p.connected, p.loading)};
	@media (min-width: 992px) {
		width: 420px;
		margin-left: 20px;
		margin-right: 0px;
		min-height: 725px;
		margin-left: 20px;
	}
`;

const PanelContainer = styled.div`
	height: calc(100% - 80px);
	@media (min-width: 992px) {
		height: initial;
	}
`;

const Header = styled.div`
	height: 70px;
	width: 100%;
	padding: 20px;
`;

const Shadow = styled.div`
	height: 10px;
	background: transparent linear-gradient(180deg, #000000 0%, #00000000 100%) 0%
		0% no-repeat padding-box;
	opacity: 0.2;
`;

const Title = styled.div`
	font-size: 20px;
	font-weight: bold;
	color: #fff;
	margin-bottom: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 23ch;
	white-space: nowrap;
	cursor: default;
	@media (max-width: 720px) {
		max-width: 17ch;
	}
`;

const StatusConnection = styled.div`
	width: 40px;
	height: 40px;
	/* background: rgba(187, 191, 198, 0.15) 0% 0% no-repeat padding-box; */
`;

const ConnectionErrorText = styled.span`
	font: Bold 14px Open Sans;
	color: #ffffff;
	margin-left: 10px;
	opacity: 1;
`;

const HeaderContainer = styled.div``;

const HeaderButtons = styled.div``;

const RefreshText = styled.span`
	color: #6f6f6f;
	font-size: 12px;
`;

const ButtonRefresh = styled.button`
	background: none;
	border: none;
`;

interface Props {
	data: PlantStruct;
	loading: Boolean;
}

interface State {
	showSettings: Boolean;
	user: any;
	showMapModal: Boolean;
}

export default class Plant extends Component<Props, State> {
	state = {
		showSettings: false,
		user: undefined,
		showMapModal: false,
	};
	*renderVLinks() {
		for (let index = 0; index < this.props.data.vlinks.length; index++) {
			const vlink = this.props.data.vlinks[index];
			yield (
				<VLink
					key={vlink.id}
					plant={this.props.data}
					data={vlink}
					plantIndex={this.props.data.index}
				/>
			);
		}
	}

	componentDidMount() {
		this.setState({ user: getAuth().user });
	}

	isOwner = (id: string) => {
		let plans = (this.state.user as any).plans.filter((item) => {
			return item.role === "5bb6c21eb2ca821ed84b7c94" && item.plan === id;
		}) as Array<any>;
		return plans.length > 0;
	};

	render() {
		return (
			<Container
				className="plant-container"
				loading={this.props.loading.toString()}
				connected={this.props.data.connected}
			>
				{this.props.loading ? (
					<div
						className="d-flex justify-content-center align-items-center mt-4"
						style={{ height: "100%" }}
					>
						<FontAwesomeIcon inverse size="3x" icon={faSync} spin />
					</div>
				) : (
					<div>
						{this.props.data.vlinks.length > 0 &&
							this.props.data.vlinks[0].location != null && (
								<MapModal
									lat={this.props.data.vlinks![0]!.location.lat}
									lng={this.props.data.vlinks![0]!.location.lng}
									show={this.state.showMapModal}
									onHide={() => {
										this.setState({
											showMapModal: false,
										});
									}}
								/>
							)}
						<Header className="d-flex justify-content-around">
							<StatusConnection
								onClick={() => {
									if (
										this.props.data.vlinks.length > 0 &&
										this.props.data.vlinks[0].location != null
									) {
										this.setState({
											showMapModal: true,
										});
									}
								}}
							>
								<img
									height="40"
									alt="Conexão"
									src={
										this.props.data.vlinks.some(
											(t) => t.name.startsWith("VA") || t.name.startsWith("VE")
										)
											? this.props.data.vlinks.some((t) =>
													t.name.startsWith("VA")
											  )
												? plantaVa
												: plantaVe
											: this.props.data.vlinks.length > 0
											? this.props.data.vlinks[0].gprs
												? plantaGprs
												: plantaLocal
											: plantaLocal
									}
								/>
							</StatusConnection>
							<div
								className="d-flex flex-column flex-grow-1"
								style={{ marginLeft: 16, height: 50 }}
							>
								<HeaderContainer className="d-flex flex-row justify-content-between">
									<Title title={this.props.data.name} className="flex-fill">
										{this.props.data.name}
									</Title>
									<HeaderButtons className="d-flex">
										{this.state.user != null &&
											this.isOwner(this.props.data.id) && (
												<Button
													style={{
														marginLeft: 10,
													}}
													size="sm"
													variant="secondary"
													title="Configuração da Planta"
													onClick={() => {
														this.setState({
															showSettings: true,
														});
													}}
												>
													<FontAwesomeIcon icon={faCog} />
												</Button>
											)}
									</HeaderButtons>
								</HeaderContainer>
								<RefreshText>
									Última atualização:{" "}
									{moment(this.props.data.updatedAt).format(
										"DD/MM/YYYY - HH:mm:ss"
									)}
								</RefreshText>
							</div>
						</Header>
						<Shadow />
						{!this.state.showSettings ? (
							<PanelContainer className="p-2">
								{!this.props.data.connected && (
									<div className="d-flex flex-row h-100">
										<div className="d-flex align-self-center flex-row w-100 justify-content-center">
											<img src={surfaceOff} height="36" />
											<ConnectionErrorText className="text-center mt-3">
												Erro de conexão à planta.
											</ConnectionErrorText>
										</div>
									</div>
								)}
								{Array.from(this.renderVLinks())}
							</PanelContainer>
						) : (
							<PlantSettings
								data={this.props.data}
								back={() => {
									this.setState({ showSettings: false });
								}}
							/>
						)}
					</div>
				)}
			</Container>
		);
	}
}
