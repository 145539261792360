import React from "react";
import {
	GoogleMap,
	Marker,
	withGoogleMap,
	withScriptjs,
	Circle
} from "react-google-maps";
import { compose, withProps } from "recompose";
import markerImage from "../../assets/marker.png";

const Map = compose(
	withProps({
		googleMapURL:
			"https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyD3OLZEjUCaLwnQSLntMt1ye0ecs2F7nCs",
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: `400px` }} />,
		mapElement: <div style={{ height: `100%` }} />
	}),
	withScriptjs,
	withGoogleMap
)(props => (
	<GoogleMap
	
		defaultZoom={16}
		defaultCenter={{ lat: props.latitude, lng: props.longitude }}
	>
		<Circle
			defaultCenter={{ lat: props.latitude, lng: props.longitude }}
			defaultOptions={{
				fillColor: "#2D7AED",
				strokeColor: "#2D7AED"
			}}
			defaultRadius={200}
		/>
		<Marker
			defaultOptions={{
				icon: markerImage
			}}
			position={{ lat: props.latitude, lng: props.longitude }}
		/>
	</GoogleMap>
));

export default Map;
