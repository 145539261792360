import React from "react";
import styled from "styled-components";
import plantaGprs from "../../assets/planta-gprs.png";
import plantaLocal from "../../assets/planta-local.png";
import * as MomentNamespace from "moment";
import { extendMoment } from "moment-range";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Subscribe } from "unstated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTrash,
	faSync,
	faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import DeleteModal from "../../components/DeleteModal";
import {
	PlantStruct,
	VLinkStruct,
	TankStruct,
	ReadingStruct,
} from "../../types/types";
import PlantContainer from "../../containers/PlantContainer";
import { Line } from "react-chartjs-2";
import api from "../../services/api";
import {
	Button,
	ButtonToolbar,
	ToggleButtonGroup,
	ToggleButton,
	Table,
	Modal,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { getAuth } from "../../services/auth";
const moment = extendMoment(MomentNamespace);
const Container = styled.div`
	padding: 20px;
`;

const ContainerRound = styled.div`
	min-height: 90px;
	width: 100%;
	padding: 20px;
	background: #3a3848 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 15px #00000029;
	border-radius: 10px;
`;

const Title = styled.h2`
	text-align: center;
	font: Bold 20px/27px Open Sans;
	letter-spacing: 0;
	color: #ffffff;
	opacity: 1;
	@media (max-width: 720px) {
		text-overflow: ellipsis;
		font-size: 14px;
		overflow-wrap: break-word;
		white-space: nowrap;
		overflow: hidden;
		max-width: 174px;
	}
`;
const TitleMain = styled.h2`
	text-align: center;
	font: Bold 20px/27px Open Sans;
	letter-spacing: 0;
	color: #ffffff;
	opacity: 1;
	@media (max-width: 720px) {
		font-size: 14px;
	}
`;

const Header = styled.div`
	width: 100%;
`;
const HeaderContainer = styled.div``;

const HeaderButtons = styled.div``;

const StatusConnection = styled.div`
	width: 40px;
	height: 40px;
`;

const RefreshText = styled.span`
	color: #6f6f6f;
	font-size: 12px;
`;
type PathParamsType = {
	plantId: string;
	barrierId: string;
	deviceId: string;
};
type PropsType = RouteComponentProps<PathParamsType> & {};

type ReadingType = {
	t: Date;
	y: number;
};

interface State {
	modeChart: boolean;
	dateStart: Date | undefined;
	dateEnd: Date | undefined;
	days: Number;
	plantId: string | undefined;
	deviceId: string | undefined;
	rows: Array<ReadingStruct>;
	dataAvailable: Array<ReadingType>;
	dataUnavailable: Array<ReadingType>;
	isVolume: boolean;
	visibleDeleteModal: boolean;
	user: any | undefined;
	loading: boolean;
}

const PlantSelect = (props) => {
	const plant: PlantStruct | undefined =
		props.plants[parseInt(props.plantId)];
	let barrier: VLinkStruct | null = null;
	let tank: TankStruct | null = null;
	if (plant) {
		if (plant!.premium) {
			barrier = plant.vlinks[parseInt(props.barrierId)];
			if (!barrier) {
				props.navigation.replace("/dashboard");
			} else {
				tank = barrier.tanks[parseInt(props.deviceId)];
				if (!tank) {
					props.navigation.replace("/dashboard");
				}
			}
			if (plant && barrier && tank && props.fetchReadings) {
				props.fetchReadings(
					plant!.id,
					tank!.id,
					true,
					tank.isVolume,
					plant.connected
				);
			}
		} else {
			props.navigation.replace("/dashboard");
		}
	} else {
		props.navigation.replace("/dashboard");
	}
	return props.children({ plant, barrier, tank });
};

export class SingleTank extends React.Component<PropsType, State> {
	state = {
		modeChart: true,
		loading: true,
		dateEnd: moment().startOf("day").toDate(),
		dateStart: moment().subtract(7, "days").toDate(),
		days: 7,
		plantId: undefined,
		deviceId: undefined,
		rows: new Array<ReadingStruct>(),
		dataUnavailable: new Array<ReadingType>(),
		dataAvailable: new Array<ReadingType>(),
		isVolume: false,
		visibleDeleteModal: false,
		user: undefined,
	};

	fetched = false;

	async fetchReadings(
		plantId,
		deviceId,
		saveState = false,
		isVolume,
		plantConnected = true
	) {
		if (this.fetched) {
			return;
		}
		if (saveState) {
			this.setState({
				plantId: plantId,
				deviceId: deviceId,
				isVolume,
			});
		}

		this.fetched = true;
		let filter: { device: string; $or: any } = {
			device: deviceId,
			$or: [],
		};
		let select = {
			start: 1,
			end: 1,
			occurrences: 1,
			serial_number: 1,
			serial_prefix: 1,
			value: 1,
			liquid_level: 1,
			_id: 0,
		};

		if (this.state.dateStart && this.state.dateEnd) {
			let dateStart = moment(this.state.dateStart!)
				.startOf("day")
				.utc()
				.toISOString();
			let dateEnd = moment(this.state.dateEnd!)
				.endOf("day")
				.utc()
				.toISOString();
			if (moment().diff(moment(this.state.dateEnd!), "days") > 0) {
				filter.$or = [
					{
						start: {
							$lte: dateEnd,
							$gte: dateStart,
						},
					},
				];
			} else {
				filter.$or = [
					{
						start: {
							$lte: dateEnd,
							$gte: dateStart,
						},
					},
					{ end: null },
				];
			}
		}
		this.setState({
			loading: true,
		});

		let response = await api.get(
			`/local/readings/${plantId}?limit=1000&page=0&query=${encodeURI(
				JSON.stringify(filter)
			)}&select=${encodeURI(JSON.stringify(select))}`
		);
		let rows = response.data.rows as Array<ReadingStruct>;
		let dataAvailable = new Array<ReadingType>();
		let dataUnavailable = new Array<ReadingType>();
		let dataTable = new Array<ReadingStruct>();
		let lastDate: undefined | Date | string = undefined;
		let lastValue: undefined | number | null = undefined;
		for (let index = 0; index < rows.length; index++) {
			const item = rows[index];
			let dateStart = moment.utc(item.start).local().toDate();
			let dateEnd = item.end
				? moment.utc(item.end).local().toDate()
				: new Date();
			if (
				lastDate &&
				(moment
					.utc(item.start)
					.local()
					.diff(moment(lastDate), "seconds") > 0 ||
					(isVolume && !item.value) ||
					(!isVolume && !item.liquid_level))
			) {
				dataAvailable.push({
					y: 0,
					t: item.liquid_level || item.value ? lastDate : dateStart,
				});
				dataUnavailable.push({
					y: lastValue as number,
					t: item.liquid_level || item.value ? lastDate : dateStart,
				});
				dataUnavailable.push({
					y: lastValue as number,
					t:
						item.liquid_level || item.value
							? moment.utc(dateStart).local().toDate()
							: dateEnd,
				});
				dataTable.push({
					_id: index.toString(),
					id: index.toString() + "_1",
					device: deviceId,
					occurrences: item.occurrences,
					serial_number: item.serial_number,
					serial_prefix: item.serial_prefix,
					start: lastDate.toISOString(),
					end:
						item.liquid_level || item.value
							? dateStart.toISOString()
							: dateEnd.toISOString(),
					value: item.value ? item.value : 0,
					liquid_level: item.liquid_level ? item.liquid_level : 0,
					available: false,
				});
			}

			if (item.value || item.liquid_level) {
				dataUnavailable.push({
					y: 0,
					t: dateStart,
				});
				dataTable.push({
					_id: index.toString(),
					id: index.toString() + "_2",
					device: deviceId,
					occurrences: item.occurrences,
					serial_number: item.serial_number,
					serial_prefix: item.serial_prefix,
					start: item.start,
					end: item.end ? item.end : new Date().toISOString(),
					value: item.value ? item.value : 0,
					liquid_level: item.liquid_level ? item.liquid_level : 0,
					available: true,
				});
				dataAvailable.push({
					y: isVolume
						? item.value
							? item.value
							: 0
						: item.liquid_level
						? item.liquid_level
						: 0,
					t: dateStart,
				});
				dataAvailable.push({
					y: isVolume
						? item.value
							? item.value
							: 0
						: item.liquid_level
						? item.liquid_level
						: 0,
					t: dateEnd,
				});
			}

			lastDate = dateEnd;
			lastValue =
				item.value || item.liquid_level
					? isVolume
						? item.value
						: item.liquid_level
					: lastValue;
		}
		if (!plantConnected) {
			let id = `siv_${(Math.random() * 9032).toString()}`;
			dataTable.push({
				_id: id,
				id: id + "_2",
				device: deviceId,
				occurrences: 1,
				serial_number: dataTable[0].serial_number,
				serial_prefix: dataTable[0].serial_prefix,
				start:
					dataTable[dataTable.length - 1].end != undefined
						? dataTable[dataTable.length - 1].end!
						: dataTable[dataTable.length - 1].start,
				end: new Date().toISOString(),
				value: dataTable[dataTable.length - 1].value,
				liquid_level: dataTable[dataTable.length - 1].liquid_level,
				available: false,
			});
			dataAvailable.push({
				y: 0,
				t: new Date(),
			});
			let lastValue = isVolume
				? dataTable[dataTable.length - 1].value
				: dataTable[dataTable.length - 1].liquid_level;
			dataUnavailable.push({
				y: lastValue as number,
				t: moment().local().toDate(),
			});
		}

		this.setState({
			rows: dataTable.reverse(),
			dataAvailable,
			dataUnavailable,
			loading: false,
		});
		this.fetched = true;
	}

	onChangeDays(val) {
		this.setState(
			{
				dateEnd: moment().startOf("day").toDate(),
				days: val,
				dateStart: moment().subtract(val, "days").toDate(),
			},
			() => {
				this.fetched = false;
				this.fetchReadings(
					this.state.plantId,
					this.state.deviceId,
					false,
					this.state.isVolume,
					true
				);
			}
		);
	}

	renderDeleteButton = (plant: PlantStruct) => {
		if (!this.state.user) {
			return null;
		}
		let plans = (this.state.user as any).plans.filter((item) => {
			return (
				item.role === "5bb6c21eb2ca821ed84b7c94" &&
				item.plan === plant.id
			);
		}) as Array<any>;

		return (
			plans.length > 0 && (
				<Button
					variant="secondary"
					title="Apagar Logs"
					onClick={() => {
						this.setState({
							visibleDeleteModal: true,
						});
					}}
					style={{
						marginLeft: 10,
					}}
				>
					<FontAwesomeIcon icon={faTrash} />
				</Button>
			)
		);
	};

	componentDidMount() {
		let user = getAuth().user;
		this.setState({
			user,
		});
	}

	render() {
		const dataGraph = (canvas) => {
			return {
				datasets: [
					{
						label: this.state.isVolume
							? "Volume (litros)"
							: "Nível (mm)",
						backgroundColor: "#2D7AED",
						borderWidth: 0,
						steppedLine: true,
						data: this.state.dataAvailable,
						radius: 0,
					},
					{
						label: "Indisponivel",
						steppedLine: true,
						backgroundColor: "rgba(230, 126, 34,0.20)",
						borderWidth: 0,
						data: this.state.dataUnavailable,
						radius: 0,
					},
				],
			};
		};
		return (
			<Subscribe to={[PlantContainer]}>
				{(plantContainer) => (
					<PlantSelect
						navigation={this.props.history}
						plants={plantContainer.state.plants}
						plantId={this.props.match.params.plantId}
						deviceId={this.props.match.params.deviceId}
						barrierId={this.props.match.params.barrierId}
						fetchReadings={this.fetchReadings.bind(this)}
					>
						{({ plant, barrier, tank }) =>
							plant != null && barrier != null ? (
								<Container>
									<Modal
										keyboard={false}
										backdrop="static"
										show={this.state.loading}
										onHide={() => {
											this.setState({
												loading: false,
											});
										}}
									>
										<Modal.Header>
											<Modal.Title>
												Aguarde...
											</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<p className="text-center">
												<FontAwesomeIcon
													inverse
													size="2x"
													icon={faSync}
													spin
													className="mr-3"
												/>{" "}
												Aguarde...
											</p>
										</Modal.Body>
									</Modal>
									<DeleteModal
										plantId={plant.id}
										deviceId={tank.id}
										type={0}
										show={this.state.visibleDeleteModal}
										onComplete={async (success) => {
											this.setState({
												visibleDeleteModal: false,
											});
											if (success) {
												await this.fetchReadings(
													plant.id,
													barrier.id,
													tank.id,
													false,
													plant.connected
												);
											}
										}}
									/>

									<ContainerRound>
										<Header className="d-flex justify-content-around">
											<div>
												<Button
													variant="secondary"
													title="Voltar"
													onClick={() =>
														this.props.history.goBack()
													}
												>
													<FontAwesomeIcon
														icon={faArrowLeft}
													/>
												</Button>
											</div>
											<StatusConnection
												style={{ marginLeft: 10 }}
											>
												<img
													height={40}
													alt="status"
													src={
														barrier.location
															? plantaGprs
															: plantaLocal
													}
												/>
											</StatusConnection>
											<div
												className="d-flex flex-column flex-grow-1"
												style={{
													marginLeft: 16,
													height: 50,
												}}
											>
												<HeaderContainer className="d-flex flex-row justify-content-between">
													<Title title={plant.name}>
														{plant.name}
													</Title>
													<HeaderButtons className="d-flex">
														{this.renderDeleteButton(
															plant
														)}
													</HeaderButtons>
												</HeaderContainer>
												<RefreshText>
													Última atualização:{" "}
													{moment(plant.updatedAt)
														.local()
														.format(
															"DD/MM/YYYY - HH:mm"
														)}
												</RefreshText>
											</div>
										</Header>
									</ContainerRound>
									<ContainerRound style={{ marginTop: 20 }}>
										<TitleMain>
											Registro de variações de volumes -{" "}
											{tank.name}
										</TitleMain>
										<div className="d-md-flex flex-md-row flex-column mt-5 justify-content-between flex-wrap">
											<div className="d-md-flex flex-wrap">
												<Button
													onClick={() => {
														this.setState({
															modeChart: true,
														});
													}}
													style={
														!this.state.modeChart
															? {
																	backgroundColor:
																		"#32303A",
																	borderColor:
																		"#32303A",
															  }
															: {}
													}
												>
													Gráfico
												</Button>
												<Button
													className="ml-2"
													onClick={() => {
														this.setState({
															modeChart: false,
														});
													}}
													style={
														this.state.modeChart
															? {
																	backgroundColor:
																		"#32303A",
																	borderColor:
																		"#32303A",
															  }
															: {}
													}
												>
													Dados
												</Button>
												<ButtonToolbar>
													<ToggleButtonGroup
														type="radio"
														className="ml-md-3 mt-3 mt-md-0"
														name="options"
														value={this.state.days}
														onChange={this.onChangeDays.bind(
															this
														)}
														defaultValue={1}
													>
														<ToggleButton
															variant="link"
															value={1}
														>
															1 dia
														</ToggleButton>
														<ToggleButton
															variant="link"
															value={7}
														>
															1 semana
														</ToggleButton>
														<ToggleButton
															variant="link"
															value={30}
														>
															1 mês
														</ToggleButton>
													</ToggleButtonGroup>
												</ButtonToolbar>
											</div>
											<div className="d-flex mt-md-0 mt-3 align-items-center">
												<label
													className="m-0"
													htmlFor="dateStart"
												>
													De
												</label>
												<DatePicker
													customInput={
														<input
															className="form-control ml-2"
															type="text"
															name="dateStart"
														/>
													}
													locale="pt-BR"
													dateFormat="dd/MM/yyyy"
													selected={
														this.state.dateStart
															? this.state
																	.dateStart
															: undefined
													}
													onChange={(date) => {
														this.setState({
															dateStart: date!,
														});
													}}
												/>
												<label
													htmlFor="dateEnd"
													className="mb-0 ml-3"
												>
													Até
												</label>
												<DatePicker
													customInput={
														<input
															className="form-control ml-2"
															type="text"
															name="dateEnd"
															id="dateEnd"
														/>
													}
													selected={
														this.state.dateEnd
															? this.state.dateEnd
															: undefined
													}
													locale="pt-BR"
													dateFormat="dd/MM/yyyy"
													onChange={(date) => {
														this.setState({
															dateEnd: date!,
														});
													}}
												/>
												<Button
													style={{ marginLeft: 15 }}
													onClick={() => {
														this.fetched = false;
														this.fetchReadings(
															this.state.plantId,
															this.state.deviceId,
															false,
															this.state.isVolume,
															plant.connected
														);
													}}
												>
													Filtrar
												</Button>
											</div>
										</div>
										<div className="mt-5">
											{this.state.modeChart ? (
												<Line
													data={dataGraph}
													width={100}
													height={300}
													options={{
														steppedLine: true,
														responsive: true,
														maintainAspectRatio: false,
														elements: {
															point: {
																radius: 0,
															},
														},
														hover: {
															intersect: false,
														},
														tooltips: {
															mode: "nearest",
															filter: function (
																tooltipItem
															) {
																return (
																	window.innerWidth >
																	960
																);
															},
														},
														scales: {
															xAxes: [
																{
																	display: true,
																	type:
																		"time",

																	bounds:
																		"ticks",
																	time: {
																		displayFormats: {
																			millisecond:
																				"MMM DD",
																			second:
																				"MMM DD",
																			minute:
																				"DD/MM/YY HH:mm",
																			hour:
																				"DD/MM/YY HH:mm",
																			day:
																				"DD/MM/YY HH:mm",
																			week:
																				"DD/MM/YY HH:mm",
																			month:
																				"DD/MM/YY HH:mm",
																			quarter:
																				"DD/MM/YY HH:mm",
																			year:
																				"DD/MM/YY HH:mm",
																		},
																		minUnit:
																			"minute",
																	},
																	distribution:
																		"linear",
																	gridLines: {
																		display: false,
																	},
																	ticks: {
																		fontColor:
																			"#fff",
																	},
																	scaleLabel: {
																		display: true,
																		fontColor:
																			"white",
																		labelString:
																			"Período",
																	},
																},
															],
															yAxes: [
																{
																	display: true,
																	gridLines: {
																		display: true,
																		color:
																			"#6F6F6F",
																	},
																	ticks: {
																		fontColor:
																			"#fff",
																		suggestedMin: 0,
																		precision: 1,
																		beginAtZero: true,
																		suggestedMax:
																			tank.maxValue,
																	},
																	scaleLabel: {
																		display: true,
																		fontColor:
																			"white",
																		labelString: tank.isVolume
																			? "Quantidade (Litros)"
																			: "Nível (mm)",
																	},
																},
															],
														},
														legend: {
															position: "bottom",
															labels: {
																fontColor:
																	"#fff",
															},
															legend: {
																display: true,
																labels: {
																	fontColor:
																		"#fff",
																},
															},
														},
													}}
												/>
											) : (
												<div>
													<Table
														className="mt-4"
														responsive="md"
														striped
														hover
														size="sm"
														variant="dark"
													>
														<thead>
															<tr>
																<th>
																	Data de
																	Início
																</th>
																<th>
																	Data da
																	Última
																	Leitura
																</th>
																<th>Status</th>
																<th>Leitura</th>
																<th>
																	Ocorrências
																</th>
																<th>
																	Dispositivo
																</th>
															</tr>
														</thead>
														<tbody>
															{this.state.rows.map(
																(item) => {
																	return (
																		<tr
																			key={
																				item.id
																			}
																		>
																			<td>
																				{moment(
																					item.start
																				).format(
																					"DD/MM/YYYY HH:mm:ss"
																				)}
																			</td>
																			<td>
																				{item.end
																					? moment(
																							item.end
																					  ).format(
																							"DD/MM/YYYY HH:mm:ss"
																					  )
																					: ""}
																			</td>
																			<td>
																				{item.available &&
																				(item.value !=
																					null ||
																					item.liquid_level !=
																						null)
																					? "Disponível"
																					: "Indisponível"}
																			</td>
																			<td>
																				{item.available &&
																				(item.value !=
																					null ||
																					item.liquid_level !=
																						null)
																					? tank.isVolume
																						? `${item.value} litros`
																						: `${item.liquid_level.toFixed(
																								2
																						  )} mm`
																					: null}
																			</td>
																			<td>
																				{item.available &&
																					item.occurrences}
																			</td>
																			<td>
																				{
																					item.serial_prefix
																				}{" "}
																				{
																					item.serial_number
																				}
																			</td>
																		</tr>
																	);
																}
															)}
														</tbody>
													</Table>
												</div>
											)}
										</div>
									</ContainerRound>
								</Container>
							) : (
								<div>Carregando</div>
							)
						}
					</PlantSelect>
				)}
			</Subscribe>
		);
	}
}

export default withRouter(SingleTank);
