import React, { useState } from "react";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useForm from "react-hook-form";
import api from "../../services/api";

interface Props extends ModalProps {
	onHide: any;
}
export default function AddRole(props: Props) {
	const { register, handleSubmit, errors } = useForm();
	const [error, setError] = useState("");
	const [success, setSuccess] = useState(false);
	const onSubmit = async (data) => {
		try {
			const { name } = data;
			let params = {
				updated_at: null,
				created_at: null,
				_id: "SIVWeb.model.Role-2",
				name: name,
			};
			await api.post(`/roles`, params);
			setSuccess(true);
		} catch (e) {
			const err = e as any;
			if (err.response != null) {
				setError(err.response.data);
			} else {
				setError("Verifique sua conexão com a internet e tente novamente T.T");
			}
		}
	};
	return (
		<Modal
			{...props}
			size="sm"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{success ? `Papél Adicionado` : `Adicionar Papél`}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{success ? (
					<div>O papél foi criado com sucesso</div>
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						{error && (
							<div className="alert alert-danger" role="alert">
								{error}
							</div>
						)}
						<p>
							Um papel é a descrição de uma função que pode ser concedida a um
							usuário do sistema. Dado o Papel, o usuário tera poderes de
							executar as tarefas descritas na papel
						</p>
						<input
							className="form-control"
							type="text"
							maxLength={40}
							name="name"
							ref={register({
								required: "Obrigatório",
							})}
							placeholder="Nome do Papél"
						/>
						{errors.name && errors.name.message}
						<div
							className="d-flex justify-content-between"
							style={{ marginTop: "30px", float: "right" }}
						>
							<Button
								onClick={() => {
									props.onHide();
								}}
								variant="secondary"
								type="button"
								className="mr-2"
							>
								Cancelar
							</Button>
							<Button type="submit">Salvar</Button>
						</div>
					</form>
				)}
				{success && (
					<Button
						className="mt-2"
						type="button"
						onClick={() => {
							props.onHide();
						}}
					>
						OK
					</Button>
				)}
			</Modal.Body>
			<Modal.Footer></Modal.Footer>
		</Modal>
	);
}
