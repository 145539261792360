import React, { Component } from "react";
import styled from "styled-components";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import truck from "../../assets/truck.svg";
import DatePicker from "react-datepicker";
import moment from "moment";
import api from "../../services/api";
import pdfMake from "pdfmake/build/pdfmake";
import { vfs } from "../../utils/vfs_fonts";
import { getAuth } from "../../services/auth";
import Numeral from "numeral";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { exportCSVFile } from "../../utils/export";

const Container = styled.div`
	padding: 20px;
`;

const ContainerRound = styled.div`
	min-height: 90px;
	width: 100%;
	padding: 20px;
	background: #3a3848 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 15px #00000029;
	border-radius: 10px;
`;

interface State {
	dateStart: Date;
	dateEnd: Date;
	plants: Array<{ value: string; label: string }>;
	selectedPlants: Array<any>;
	generating: boolean;
}

interface Props {}

export class ReportDischarge extends Component<Props, State> {
	state = {
		dateStart: moment().subtract("3", "months").startOf("day").toDate(),
		dateEnd: moment().endOf("day").toDate(),
		plants: new Array<{ value: string; label: string }>(),
		selectedPlants: new Array<any>(),
		generating: false,
	};

	async componentDidMount() {
		let response = await api.get("/plans/getUserAllPremiums");
		let items: Array<{ label: string; value: string }> = response.data.map(
			(item) => {
				return {
					value: item._id,
					label: item.name,
				};
			}
		);
		this.setState({
			plants: items,
		});
	}

	onSubmit = async (event) => {
		event.preventDefault();
	};

	generateData = async (type: number) => {
		if (this.state.selectedPlants.length === 0) {
			alert("Selecione ao menos uma planta");
			return;
		}
		try {
			this.setState({ generating: true });
			let response = await api.post("/plans/report-discharge", {
				plans: this.state.selectedPlants.map((item) => {
					return item.value;
				}),
				dateStart: moment(this.state.dateStart)
					.startOf("day")
					.utc()
					.format("YYYY-MM-DD"),
				dateEnd: moment(this.state.dateEnd)
					.endOf("day")
					.utc()
					.format("YYYY-MM-DD"),
			});
			if (type == 1) {
				this.generatePDF(response.data);
			} else {
				this.generateCSV(response.data);
			}
		} catch (e) {
		} finally {
			this.setState({ generating: false });
		}
	};

	generateCSV(data: any) {
		let items = new Array<{
			plant: string;
			dateStart: string;
			dateEnd: string;
			valueStart: string;
			valueEnd: string;
			variationTime: string;
			device: string;
		}>();
		for (let index = 0; index < data.length; index++) {
			const itemA = data[index];
			items.push(
				...itemA.data.map((itemB) => {
					let duration = moment
						.duration(
							moment
								.utc(itemB.dateEnd)
								.diff(moment.utc(itemB.dateStart))
						)
						.asSeconds();
					return {
						plant: itemA.plant,
						device: itemB.device,
						dateStart: moment
							.utc(itemB.dateStart)
							.local()
							.format("DD/MM/YYYY HH:mm:ss"),
						dateEnd: moment
							.utc(itemB.dateEnd)
							.local()
							.format("DD/MM/YYYY HH:mm:ss"),
						valueStart: itemB.valueStart,
						valueEnd: itemB.valueEnd,
						variation: itemB.unavailable
							? `Sistema indisponivel`
							: itemB.variation,
						variationTime: itemB.unavailable
							? `Sistema indisponivel`
							: Numeral(duration).format("00:00:00"),
					};
				})
			);
		}
		var headersCSV = {
			plant: "PLANTA",
			device: "PRODUTO",
			dateStart: "T.INICIAL",
			dateEnd: "T.FINAL",
			valueStart: "V.INICIAL",
			valueEnd: "V.FINAL",
			variation: "VARIACAO LITROS",
			variationTime: "VARIACAO TEMPO",
		};
		exportCSVFile(headersCSV, items, "relatorio-discharge-csv");
	}

	generatePDF(data: any) {
		let email = getAuth().user.email;
		pdfMake.vfs = vfs;
		pdfMake.fonts = {
			Roboto: {
				normal: "Roboto-Regular.ttf",
				bold: "Roboto-Bold.ttf",
				italics: "Roboto-Italic.ttf",
				bolditalics: "Roboto-BoldItalic.ttf",
			},
		};

		let content = data.map((item, index) => {
			let rowsTanks = item.data.map((row) => {
				let duration = moment
					.duration(
						moment.utc(row.dateEnd).diff(moment.utc(row.dateStart))
					)
					.asSeconds();
				return [
					row.device,
					`${moment
						.utc(row.dateStart)
						.local()
						.format("DD/MM/YYYY HH:mm:ss")} (${Numeral(
						row.valueStart
					).format("0,0")}L)`,
					`${moment
						.utc(row.dateEnd)
						.local()
						.format("DD/MM/YYYY HH:mm:ss")} (${Numeral(
						row.valueEnd
					).format("0,0")}L)`,
					row.unavailable
						? `Sistema indisponível`
						: ` ${Numeral(row.variation).format(
								"0,0"
						  )}L - ${Numeral(duration).format("00:00:00")}`,
				];
			});
			return [
				{
					margin: [0, index > 0 ? 40 : 0, 0, 0],
					text: `${item.plant} - ${moment().format(
						"DD/MM/YYYY HH:mm"
					)}`,
					style: "header",
				},
				{
					text: `Usuário: ${email}`,
					style: "header",
					margin: [0, 5, 0, 0],
				},
				{
					text: `Entradas de Estoque - ${moment(this.state.dateStart).format(
						"DD/MM/YYYY HH:mm:ss"
					)} - ${moment(this.state.dateEnd).format(
						"DD/MM/YYYY HH:mm:ss"
					)}`,
					style: "header",
					margin: [0, 20, 0, 0],
				},
				{
					layout: "noBorders",
					margin: [0, 20, 0, 0],
					table: {
						headerRows: 1,
						widths: ["*", "*", "*", "*"],

						body: [
							[
								{ text: "Produto:", bold: true },
								{ text: "Inicial:", bold: true },
								{ text: "Final:", bold: true },
								{ text: "Variacão - Tempo:", bold: true },
							],
							...rowsTanks,
						],
					},
				},
			];
		});

		var docDefinition = {
			info: {
				title: "Relatório de Entradas de Estoque",
			},
			content: content,
			defaultStyle: {
				font: "Roboto",
				fontSize: 8,
			},
			styles: {
				header: {
					fontSize: 12,
					bold: true,
				},
			},
		};
		try {
			pdfMake.createPdf(docDefinition).download("relatorio-entrada-estoque.pdf");
		} catch (err) {
			console.error(err);
		}
	}

	render() {
		return (
			<div>
				<Modal
					show={this.state.generating}
					keyboard={false}
					backdrop="static"
					onHide={() => {
						this.setState({
							generating: false,
						});
					}}
				>
					<Modal.Header>
						<Modal.Title>Aguarde...</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p className="text-center">
							<FontAwesomeIcon
								inverse
								size="2x"
								icon={faSync}
								spin
								className="mr-3"
							/>{" "}
							Gerando Relatório
						</p>
					</Modal.Body>
				</Modal>
				<Container>
					<ContainerRound className="d-flex align-items-center justify-content-between">
						<div className="d-flex align-items-center">
							<img alt="Papéis" height="32" src={truck} />
							<h2 className="ml-3 mb-0">Relatório de Entradas de Estoque</h2>
						</div>
					</ContainerRound>
					<ContainerRound
						className="p-2 d-flex"
						style={{ marginTop: 20 }}
					>
						<form onSubmit={this.onSubmit} className="w-75 m-auto">
							<div className="row">
								<div className="col-lg mt-3">
									<label className="mr-2">Plantas</label>
									<Select
										className="fg-black"
										placeholder="Selecione uma planta"
										noOptionsMessage={() => {
											return "Sem plantas selecionadas";
										}}
										isMulti={true}
										value={this.state.selectedPlants}
										onChange={(selected) => {
											this.setState({
												selectedPlants: selected,
											});
										}}
										options={this.state.plants}
									/>
								</div>
								<div className="col-lg mt-4 mt-md-3">
									<div className="row">
										<div className="col">
											<label
												className="ml-3"
												htmlFor="dateStart"
											>
												De
											</label>
											<DatePicker
												customInput={
													<input
														className="form-control ml-2"
														type="text"
														name="dateStart"
													/>
												}
												locale="pt-BR"
												dateFormat="dd/MM/yyyy"
												selected={this.state.dateStart}
												onChange={(date) => {
													this.setState({
														dateStart: moment(date!)
															.startOf("day")
															.toDate(),
													});
												}}
											/>
										</div>
										<div className="col">
											<label
												htmlFor="dateEnd"
												className="ml-3"
											>
												Até
											</label>
											<DatePicker
												customInput={
													<input
														className="form-control ml-2"
														type="text"
														name="dateEnd"
														id="dateEnd"
													/>
												}
												selected={this.state.dateEnd}
												locale="pt-BR"
												dateFormat="dd/MM/yyyy"
												onChange={(date) => {
													this.setState({
														dateEnd: moment(date!)
															.endOf("day")
															.toDate(),
													});
												}}
											/>
										</div>
									</div>
								</div>
								<div className="col-lg mt-4">
									<Button
										className="ml-lg-3 mt-lg-4"
										type="button"
										onClick={() => this.generateData(1)}
									>
										Gerar PDF
									</Button>

									<Button
										className="ml-3 mt-lg-4"
										variant="secondary"
										type="button"
										onClick={() => this.generateData(2)}
									>
										Gerar CSV
									</Button>
								</div>
							</div>
						</form>
					</ContainerRound>
				</Container>
			</div>
		);
	}
}

export default ReportDischarge;
