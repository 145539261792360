import React from "react";
import styled from "styled-components";
import arrowUp from "../../assets/arrow-up.svg";
import arrowDown from "../../assets/arrow-down.svg";
import { TankStruct, PlantStruct, VLinkStruct } from "../../types/types";
import offline from "../../assets/perda-comunicacao.png";
import falhaIndicador from "../../assets/falha-indicador.png";
import { withRouter, RouteComponentProps } from "react-router-dom";
import AppContainer from "../../containers/AppContainer";
import { Subscribe } from "unstated";
type ProgressType = {
	progress: number;
	status: Boolean;
	alarm: Boolean | undefined;
	alarmType: number | undefined;
	statusDevice: number | undefined;
	value: Number | undefined;
	plantStatus: Boolean;
};

type PathParamsType = {};
type Props = RouteComponentProps<PathParamsType> & {
	data: TankStruct;
	plantIndex: Number;
	plant: PlantStruct;
	vlink: VLinkStruct;
	vlinkIndex: Number;
	premium: Boolean;
};

const getProgressColor = (
	status: Boolean,
	alarm: Boolean | undefined,
	alarmType: number | undefined,
	statusDevice: number | undefined,
	value: Number | undefined,
	plantStatus: Boolean
) => {
	if (
		(status || alarm || statusDevice === 3) &&
		value != null &&
		plantStatus
	) {
		if (alarm || statusDevice === 3) {
			if (alarmType === 1 || statusDevice === 3) {
				return "red";
			} else if (alarmType === 2 || statusDevice === 3) {
				return "orange";
			} else if (alarmType === 3) {
				return "gray";
			} else if (alarmType === 4) {
				return "blue";
			}
		}
		return "#2d7aed";
	} else {
		return "gray";
	}
};

const Container = styled.div`
	padding: 10px;
	background-color: #32303a;
	cursor: pointer;
	border-radius: 10px;
`;

const Title = styled.div`
	color: #fff;
	font-weight: bold;
	font-size: 14px;
`;

const ProgressBar = styled.div`
	width: 100%;
	height: 10px;
	background: rgba(0, 0, 0, 0.2);
	border-radius: 5px;
`;

const FillProgressBar = styled(ProgressBar as any)`
	height: 100%;
	width: ${(p: ProgressType) => p.progress}%;
	background: ${(p: ProgressType) =>
		getProgressColor(
			p.status,
			p.alarm,
			p.alarmType,
			p.statusDevice,
			p.value,
			p.plantStatus
		)};
	text-align: center;
	font-size: 7px;
	font-weight: bold;
	color: white;
`;

const getAlarmText = (
	alarmType: Number | undefined,
	statusDevice: Number,
	fullVolume: any
) => {
	if (alarmType) {
		switch (alarmType) {
			case 1:
				return "Volume abaixo do mínimo programado";
			case 2:
				return "Volume acima do máximo programado";
			case 4:
				return "Tanque recebeu carga";
		}
		return "Falha no dispositivo!";
	} else {
		if (statusDevice === 3) {
			if (fullVolume) {
				return "Volume abaixo do mínimo programado";
			} else {
				return "Nível abaixo do mínimo programado.";
			}
		}
	}
};

function Tank(props: Props) {
	let vlinkDisabled = false;
	if (props.vlink.voltage != null && props.vlink.amp != null) {
		const { voltage, amp } = props.vlink;
		if (voltage < 1) {
			vlinkDisabled = true
		} else if (voltage > 1 && amp > 130) {
			vlinkDisabled = true
		} else if (voltage >= 4 && voltage <= 7 && amp < 130) {
			vlinkDisabled = true
		} else if (voltage > 7 && amp < 2) {
			vlinkDisabled = true
		}
	}

	return (
		<Subscribe to={[AppContainer]}>
			{(appContainer) => (
				<Container
					onClick={() => {
						if (props.premium) {
							props.history.push(
								`/plant/${props.plantIndex}/barriers/${props.vlinkIndex}/devices/${props.data.index}`
							);
						} else {
							appContainer.setState({
								showPremium: true,
							});
						}
					}}
					className="mt-2"
				>
					{(props.data.statusDevice === 1 &&
						(props.data.errorCount === 1 ||
							props.data.errorCount >= 5 ||
							props.data.reason === 3 ||
							props.data.reason === 4) &&
						props.vlink.status == 0 &&
						!props.vlink.gprs) ||
					(((props.data.statusDevice === 3 ||
						props.data.statusDevice === 1) &&
						props.data.errorCount > 0 &&
						props.vlink.gprs) || vlinkDisabled) ? (
						<div className="d-flex flex-row">
							<img
								src={
									(props.data.statusDevice === 1 &&
										props.data.errorCount == 5) ||
									props.data.reason === 4 ||
									((props.data.statusDevice === 1 &&
										props.data.errorCount === 5 &&
										props.vlink.gprs) || (vlinkDisabled))
										? offline
										: falhaIndicador
								}
								alt="Offline"
							/>
							<div className="d-flex flex-column ml-2">
								<Title>{props.data.name}</Title>
								<span
									style={{ color: "#6F6F6F", fontSize: 12 }}
								>
									{(props.data.statusDevice === 1 &&
										props.data.errorCount == 5) ||
									(props.data.reason === 4 &&
										!props.vlink.gprs) ||
									((props.data.statusDevice === 1 &&
										props.vlink.gprs &&
										props.data.errorCount === 5) || vlinkDisabled)
										? "O indicador de volume não responde"
										: ""}
									{((props.data.statusDevice === 1 ||
										props.data.statusDevice === 3) &&
										!props.vlink.gprs &&
										props.data.errorCount === 1) ||
									(props.data.reason === 3 &&
										!props.vlink.gprs) ||
									((props.data.statusDevice === 3 ||
										props.data.statusDevice === 1) &&
										props.vlink.gprs &&
										props.data.errorCount === 1)
										? "Falha no indicador de volume"
										: ""}
								</span>
							</div>
						</div>
					) : (
						<div>
							<Title>{props.data.name}</Title>
							<ProgressBar className="mt-2">
								<FillProgressBar
									alarm={props.data.status}
									statusDevice={props.data.statusDevice}
									value={
										props.data.value != null
											? props.data.value
											: props.data.liquidLevel
									}
									plantStatus={props.plant.connected}
									alarmType={props.data.alarmType}
									status={props.data.statusDevice === 0}
									progress={
										props.data.value != null ||
										props.data.liquidLevel != null
											? !props.data.isVolume
												? (((props.data.value != null
													? props.data.value
													: props.data.liquidLevel!) * 100) / props.data.maxValue)
												: ((((props.data
														.value as number) *
														100) /
														(props.data
															.maxValue as number)) as number)
											: 100
									}
								>
									{!props.data.status ? `?` : null}
								</FillProgressBar>
							</ProgressBar>
							<div
								className="d-flex justify-content-between mt-2"
								style={{ color: "#fff" }}
							>
								<span>
									{props.data.isVolume
										? "Volume (litros)"
										: "Nivel (mm)"}
								</span>
								<div style={{ fontWeight: "bold" }}>
									{(props.data.way &&
										props.data.statusDevice === 0) ||
									props.data.status ? (
										<img
											alt="Direção"
											className="mr-1"
											height="12"
											src={
												props.data.way === 1
													? arrowUp
													: arrowDown
											}
										/>
									) : null}
									{((props.data.statusDevice !== 1 ||
										!props.data.status) &&
										props.data.value != null) ||
									props.data.liquidLevel != null
										? `${
												props.data.value != null
													? props.data.value
													: props.data.liquidLevel
										  } - ${props.data.maxValue} (${
												!props.data.isVolume
													? ((((props.data.value != null
														? props.data.value
														: props.data.liquidLevel!) *
															100) /
															(props.data
																.maxValue as number)) as number).toFixed(
															0
													  )
													: ((((props.data
															.value as number) *
															100) /
															(props.data
																.maxValue as number)) as number).toFixed(
															0
													  )
										  }%)`
										: `?/? (%)`}
								</div>
							</div>
							{props.data.statusDevice === 1 ||
							props.data.error ||
							!props.data.status ||
							props.data.statusDevice === 2 ||
							props.data.statusDevice === 3 ? (
								<div
									className="blink_me"
									style={{
										color: getProgressColor(
											props.data.status,
											props.data.statusDevice === 2,
											props.data.alarmType,
											props.data.statusDevice,
											props.data.value != null
												? props.data.value
												: props.data.liquidLevel,
											props.plant.connected
										),
									}}
								>
									Situação:{" "}
									{props.data.error
										? props.vlink.status == 1 &&
										  !props.vlink.gprs
											? "Iniciando leituras..."
											: props.data.error
										: props.data.statusDevice === 2 ||
										  (props.data.statusDevice === 3 &&
												!props.vlink.gprs)
										? getAlarmText(
												props.data.alarmType,
												props.data.statusDevice,
												props.data.fullVolume
										  )
										: props.data.error != null
										? props.data.error
										: `Falha no dispositivo!`}
								</div>
							) : null}
						</div>
					)}
				</Container>
			)}
		</Subscribe>
	);
}

export default withRouter(Tank);
