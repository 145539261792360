import React, { Component } from "react";
import styled from "styled-components";
import permission from "../../assets/permission.svg";
import user from "../../assets/user.svg";
import lock from "../../assets/lock.svg";
import { withRouter, RouteComponentProps } from "react-router-dom";

const ButtonReport = styled.div`
	width: 200px;
	height: 200px;
	background: #3a3848 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 15px #00000029;
	border-radius: 10px;

	:hover {
		background-color: #2d2b3a;
		cursor: pointer;
		transition: background linear 200ms;
	}
`;

type PathParamsType = {};
type PropsType = RouteComponentProps<PathParamsType> & {};
export class Settings extends Component<PropsType> {
	render() {
		return (
			<div className="d-flex flex-column flex-sm-row h-100 justify-content-center align-items-center">
			<ButtonReport
					onClick={() => {
						this.props.history.push("/usuarios");
					}}
					className="p-2 d-flex justify-content-center align-items-center flex-column text-center"
				>
					<img alt="Gerenciar Plantas" height={60} src={user} />
					<h4 className="mt-2">Gerenciar Plantas</h4>
				</ButtonReport>
				<ButtonReport
					onClick={() => {
						this.props.history.push("/configuracoes/papeis");
					}}
					className="p-2 ml-0 ml-sm-4 mt-4 mt-sm-0 d-flex justify-content-center align-items-center flex-column text-center"
				>
					<img alt="Gerenciar Papéis" height={60} src={permission} />
					<h4 className="mt-2">Gerenciar Papéis</h4>
				</ButtonReport>
				<ButtonReport
					onClick={() => {
						this.props.history.push(
							"/configuracoes/alterar-senha-local"
						);
					}}
					className="p-2 ml-0 ml-sm-4 mt-4 mt-sm-0 d-flex justify-content-center align-items-center flex-column text-center"
				>
					<img alt="Trocar senha de um SIV Local" height={60} src={lock} />
					<h4 className="mt-2">Trocar senha de um SIV Local</h4>
				</ButtonReport>
			</div>
		);
	}
}

export default withRouter(Settings);
