import React from "react";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Map from "../Map";

interface Props extends ModalProps {
	lat: number;
	lng: number;
}

export default function MapModal(props: Props) {
	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Antena Próxima
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="mt-4">
					<Map latitude={props.lat} longitude={props.lng} />
				</div>
			</Modal.Body>
			<Modal.Footer></Modal.Footer>
		</Modal>
	);
}
