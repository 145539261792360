import React, { Component } from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import tank from "../../assets/tank.svg";
import truck from "../../assets/truck.svg";
import water from "../../assets/water.svg";
import { getAuth } from "../../services/auth";
import { withRouter, RouteComponentProps } from "react-router-dom";

const ButtonReport = styled.div`
	width: 100%;
	height: 380px;
	background: #3a3848 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 15px #00000029;
	border-radius: 10px;

	@media (min-width: 992px) {
		height: 490px;
		width: 375px;
	}
`;

const ContainerIcon = styled.div`
	width: 180px;
	height: 180px;
	background: rgba(187, 191, 198, 0.15) 0% 0% no-repeat padding-box;
	border-radius: 100%;
`;

const ContainerButtonTitle = styled.span`
	text-align: center;
	font-weight: bold;
	margin-top: 10px;
	font-size: 20px;
	letter-spacing: 0;
	color: #ffffff;
`;

const TextDescription = styled.p`
	text-align: center;
	font-size: 14;
	letter-spacing: 0;
	color: rgba(111, 111, 111, 1);
`;

type PathParamsType = {};

type PropsType = RouteComponentProps<PathParamsType> & {};

export class Reports extends Component<PropsType> {
	async componentDidMount() {
		let auth = getAuth();
		if (auth.user.role === "5bb6c21eb2ca821ed84b7c93") {
			this.props.history.replace("/configuracoes");
		}
	}
	render() {
		return (
			<div className="p-2 d-flex flex-wrap">
				<ButtonReport
					onClick={() => {
						this.props.history.push("/relatorios/entrada-estoque");
					}}
					className="ml-lg-4  ml-0 mt-4 mt-xl-4 p-4 d-flex justify-content-between flex-column align-items-center"
				>
					<ContainerIcon className="d-flex align-items-center justify-content-center">
						<img src={truck} alt="Entradas de Estoque" />
					</ContainerIcon>
					<ContainerButtonTitle>Entradas de Estoque</ContainerButtonTitle>
					<TextDescription className="pl-2 pr-2"></TextDescription>
					<Button>Gerar relatório</Button>
				</ButtonReport>
				<ButtonReport
					onClick={() => {
						this.props.history.push("/relatorios/estoque-anterior");
					}}
					className="ml-lg-4  ml-0 mt-4 mt-xl-4 p-4 d-flex justify-content-between flex-column align-items-center"
				>
					<ContainerIcon className="d-flex align-items-center justify-content-center">
						<img src={tank} alt="Tanque" />
					</ContainerIcon>
					<ContainerButtonTitle>
						Estoque Anterior
					</ContainerButtonTitle>
					<TextDescription className="pl-2 pr-2"></TextDescription>
					<Button>Gerar relatório</Button>
				</ButtonReport>
				<ButtonReport
					onClick={() => {
						this.props.history.push("/relatorios/consolidado");
					}}
					className="ml-lg-4  ml-0 mt-4 mt-xl-4 p-4 d-flex justify-content-between flex-column align-items-center"
				>
					<ContainerIcon className="d-flex align-items-center justify-content-center">
						<img src={water} alt="Consolidado" />
					</ContainerIcon>
					<ContainerButtonTitle>Consolidado</ContainerButtonTitle>
					<TextDescription className="pl-2 pr-2"></TextDescription>
					<Button>Gerar relatório</Button>
				</ButtonReport>
			</div>
		);
	}
}

export default withRouter(Reports);
