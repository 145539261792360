import React from "react";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Subscribe } from "unstated";
import { PlantStruct, VLinkStruct, SensorStruct } from "../../types/types";
import PlantContainer from "../../containers/PlantContainer";
import api from "../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt";
import more from "../../assets/more-vertical.svg";
import { exportCSVFile } from "../../utils/export";

import {
	Button,
	ButtonToolbar,
	ToggleButtonGroup,
	ToggleButton,
	Table,
} from "react-bootstrap";
import DeleteModal from "../../components/DeleteModal";
import { getAuth } from "../../services/auth";

const Container = styled.div`
	padding: 20px;
`;

const ContainerRound = styled.div`
	min-height: 90px;
	width: 100%;
	padding: 20px;
	background: #3a3848 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 15px #00000029;
	border-radius: 10px;
`;

const Title = styled.h2`
	text-align: center;
	font: Bold 20px/27px Open Sans;
	letter-spacing: 0;
	color: #ffffff;
	opacity: 1;
	@media (max-width: 720px) {
		text-overflow: ellipsis;
		font-size: 14px;
		overflow-wrap: break-word;
		white-space: nowrap;
		overflow: hidden;
		max-width: 174px;
	}
`;
const TitleMain = styled.h2`
	text-align: center;
	font: Bold 20px/27px Open Sans;
	letter-spacing: 0;
	color: #ffffff;
	opacity: 1;
	@media (max-width: 720px) {
		font-size: 14px;
	}
`;

const Header = styled.div`
	width: 100%;
`;
const HeaderContainer = styled.div``;

const HeaderButtons = styled.div``;

type SensorStyleProps = {
	data: SensorStruct;
};

const SensorStatus = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 100%;
	border: "1px solid #BBBFC6";
	background-color: ${(props: SensorStyleProps) =>
		getColor(props.data.value, props.data.problem, props.data.recognized) ||
		"white"};
`;

const getColor = (value: Boolean, problem: Boolean, recognized: Boolean) => {
	if (value && !problem && recognized) {
		return "#54D92C";
	} else if (!value && recognized) {
		return "#FF0303";
	} else {
		return "#FB9700";
	}
};

const RefreshText = styled.span`
	color: #6f6f6f;
	font-size: 12px;
`;
type PathParamsType = {
	plantId: string;
	barrierId: string;
	deviceId: string;
};
type PropsType = RouteComponentProps<PathParamsType> & {};

interface State {
	dateStart: Date | undefined;
	dateEnd: Date | undefined;
	days: Number;
	plantId: string | undefined;
	deviceId: string | undefined;
	barrierId: string | undefined;
	rows: Array<{ date: string; status: string; device: string }>;
	visibleDeleteModal: boolean;
	user: any | undefined;
}
const PlantSelect = (props) => {
	const plant: PlantStruct | undefined =
		props.plants[parseInt(props.plantId)];
	let barrier: VLinkStruct | null = null;
	let sensor: SensorStruct | null = null;
	if (plant) {
		if (plant!.premium) {
			barrier = plant.vlinks[parseInt(props.barrierId)];
			if (!barrier) {
				props.navigation.replace("/dashboard");
			} else {
				sensor = barrier.sensors[parseInt(props.deviceId)];
				if (!sensor) {
					props.navigation.replace("/dashboard");
				}
			}
			if (plant && barrier && sensor) {
				props.fetchLogs(
					plant!.id,
					barrier!.id,
					sensor!.id,
					plant.connected,
					true
				);
			}
		} else {
			props.navigation.replace("/dashboard");
		}
	} else {
		props.navigation.replace("/dashboard");
	}
	return props.children({ plant, barrier, sensor });
};

export class SingleSensor extends React.Component<PropsType, State> {
	state = {
		dateEnd: moment().startOf("day").toDate(),
		dateStart: moment().subtract(7, "days").toDate(),
		days: 7,
		plantId: undefined,
		deviceId: undefined,
		barrierId: undefined,
		rows: new Array<{
			id: string;
			date: string;
			status: string;
			device: string;
		}>(),
		visibleDeleteModal: false,
		isOwner: false,
		user: undefined,
	};
	fetched = false;
	constructor(props) {
		super(props);
		registerLocale("pt-BR", pt);
	}

	async fetchLogs(
		plantId,
		barrierId,
		deviceId,
		plantConnected,
		saveState = false
	) {
		if (this.fetched) {
			return;
		}
		if (saveState) {
			this.setState({
				plantId: plantId,
				deviceId: deviceId,
				barrierId: barrierId,
			});
		}
		this.fetched = true;

		let filter: { start?: any | undefined } = {};

		if (this.state.dateStart && this.state.dateEnd) {
			let dateStart = moment(this.state.dateStart!)
				.startOf("day")
				.utc()
				.toISOString();
			let dateEnd = moment(this.state.dateEnd!)
				.endOf("day")
				.utc()
				.toISOString();
			filter.start = {
				$gte: dateStart,
				$lte: dateEnd,
			};
		}

		let response = await api.get(
			`/plans/${plantId}/barrier/${barrierId}/device/${deviceId}?page=0&limit=25&filter=${encodeURI(
				JSON.stringify(filter)
			)}`
		);
		let rows: Array<any> = response.data.rows.map((item) => {
			return {
				id: item.id,
				date: moment
					.utc(item.start)
					.local()
					.format("DD/MM/YYYY HH:mm:ss"),
				status: this.getStatusText(
					item.recognized,
					item.dry,
					item.aware_date != null
						? moment
								.utc(item.aware_date)
								.local()
								.format("DD/MM/YYYY HH:mm:ss")
						: null,
					item.barrier_on,
					item.end
				),
				device: `${item.serial_prefix} ${item.serial_number}`,
			};
		});
		if (!plantConnected) {
			let target = {};
			let item = Object.assign(target, rows[0]);
			item.id = `${item.id}_1`;
			item.status = this.getStatusText(false, false, false, false, false);
			item.date = moment().local().format("DD/MM/YYYY HH:mm:ss");
			rows.splice(0, 0, item);
		}
		this.setState({
			rows,
		});
		this.fetched = false;
	}

	getStatusText(recognized, dry, aware_date, barrier_on, end) {
		if (recognized === false) {
			if (barrier_on === true) {
				return "Indisponível - Falha de comunicação com o sensor.";
			} else if (barrier_on === false) {
				return "Indisponível - Falha de comunicação com o VLink.";
			} else {
				if (dry) {
					return "Sem detecção de líquido";
				} else {
					let text = "Líquido detectado - ";
					if (aware_date != null) {
						text = `${text} Data de reconhecimento: ${aware_date}`;
					} else if (end) {
						text = `${text} Evento não reconhecido`;
					} else {
						text = `${text} Evento ainda não reconhecido`;
					}
					return text;
				}
			}
		} else if (dry) {
			return "Sem detecção de líquido";
		} else {
			let text = "Líquido detectado - ";
			if (aware_date != null) {
				text = `${text} Data de reconhecimento: ${aware_date}`;
			} else if (end) {
				text = `${text} Evento não reconhecido`;
			} else {
				text = `${text} Evento ainda não reconhecido`;
			}
			return text;
		}
	}

	componentDidMount() {
		let user = getAuth().user;
		this.setState({
			user,
		});
	}

	renderDeleteButton = (plant: PlantStruct) => {
		if (!this.state.user) {
			return null;
		}
		let plans = (this.state.user as any).plans.filter((item) => {
			return (
				item.role === "5bb6c21eb2ca821ed84b7c94" &&
				item.plan === plant.id
			);
		}) as Array<any>;

		return (
			plans.length > 0 && (
				<Button
					variant="secondary"
					title="Apagar Logs"
					onClick={() => {
						this.setState({
							visibleDeleteModal: true,
						});
					}}
					style={{
						marginLeft: 10,
					}}
				>
					<FontAwesomeIcon icon={faTrash} />
				</Button>
			)
		);
	};

	onChangeDays(val) {
		this.setState({
			dateEnd: moment().toDate(),
			days: val,
			dateStart: moment().subtract(val, "days").toDate(),
		});
	}

	statusText(sensor) {
		if (sensor.value && !sensor.problem && sensor.recognized) {
			return "Normal";
		} else if (!sensor.value && sensor.recognized) {
			return "Líquido detectado";
		} else {
			return "Falha de Conexão";
		}
	}

	exportCSV() {
		var headers = {
			date: "Data",
			status: "Status",
			device: "Dispositivo",
		};
		exportCSVFile(headers, this.state.rows, "log");
	}

	render() {
		return (
			<Subscribe to={[PlantContainer]}>
				{(plantContainer) => (
					<PlantSelect
						navigation={this.props.history}
						plants={plantContainer.state.plants}
						plantId={this.props.match.params.plantId}
						deviceId={this.props.match.params.deviceId}
						barrierId={this.props.match.params.barrierId}
						fetchLogs={this.fetchLogs.bind(this)}
					>
						{({ plant, barrier, sensor }) =>
							plant != null &&
							barrier != null &&
							sensor != null ? (
								<Container>
									<DeleteModal
										plantId={plant.id}
										deviceId={sensor.id}
										type={1}
										show={this.state.visibleDeleteModal}
										onComplete={async (success) => {
											this.setState({
												visibleDeleteModal: false,
											});
											if (success) {
												await this.fetchLogs(
													plant.id,
													barrier.id,
													sensor.id,
													plant.connected,
													false
												);
											}
										}}
									/>
									<ContainerRound>
										<Header className="d-flex justify-content-around">
											<div>
												<Button
													variant="secondary"
													title="Voltar"
													onClick={() =>
														this.props.history.goBack()
													}
												>
													<FontAwesomeIcon
														icon={faArrowLeft}
													/>
												</Button>
											</div>
											<SensorStatus
												className="ml-2"
												data={sensor}
											/>
											<div
												className="d-flex flex-column flex-grow-1"
												style={{
													marginLeft: 16,
													height: 50,
												}}
											>
												<HeaderContainer className="d-flex flex-row justify-content-between">
													<Title title={sensor.name}>
														{sensor.name} - Status:{" "}
														{this.statusText(
															sensor
														)}
													</Title>
													<HeaderButtons className="d-flex">
														{this.renderDeleteButton(
															plant
														)}
													</HeaderButtons>
												</HeaderContainer>
												<RefreshText>
													Última atualização:{" "}
													{moment(plant.updatedAt)
														.local()
														.format(
															"DD/MM/YYYY - HH:mm"
														)}
												</RefreshText>
											</div>
										</Header>
									</ContainerRound>
									<ContainerRound style={{ marginTop: 20 }}>
										<TitleMain>
											Registro de status do sensor
										</TitleMain>
										<div className="d-flex flex-md-row flex-column mt-5 justify-content-between">
											<div className="d-flex">
												<ButtonToolbar>
													<ToggleButtonGroup
														type="radio"
														className="ml-3"
														name="options"
														value={this.state.days}
														onChange={this.onChangeDays.bind(
															this
														)}
														defaultValue={1}
													>
														<ToggleButton
															variant="link"
															value={1}
														>
															1 dia
														</ToggleButton>
														<ToggleButton
															variant="link"
															value={7}
														>
															1 semana
														</ToggleButton>
														<ToggleButton
															variant="link"
															value={30}
														>
															1 mês
														</ToggleButton>
													</ToggleButtonGroup>
												</ButtonToolbar>
											</div>
											<div className="d-flex mt-md-0 mt-3 align-items-center">
												<label
													className="m-0"
													htmlFor="dateStart"
												>
													De
												</label>
												<DatePicker
													customInput={
														<input
															className="form-control ml-2"
															type="text"
															name="dateStart"
														/>
													}
													locale="pt-BR"
													dateFormat="dd/MM/yyyy"
													selected={
														this.state.dateStart
													}
													onChange={(date) => {
														this.setState({
															dateStart: date!,
														});
													}}
												/>
												<label
													htmlFor="dateEnd"
													className="mb-0 ml-3"
												>
													Até
												</label>
												<DatePicker
													customInput={
														<input
															className="form-control ml-2"
															type="text"
															name="dateEnd"
															id="dateEnd"
														/>
													}
													selected={
														this.state.dateEnd
													}
													locale="pt-BR"
													dateFormat="dd/MM/yyyy"
													onChange={(date) => {
														this.setState({
															dateEnd: date!,
														});
													}}
												/>
												<Button
													style={{ marginLeft: 15 }}
													onClick={() => {
														this.fetchLogs.bind(
															this,
															this.props.match
																.params.plantId,
															this.props.match
																.params
																.barrierId,
															plant.connected,
															this.props.match
																.params.deviceId
														);
													}}
												>
													Filtrar
												</Button>
												<Dropdown
													style={{ marginLeft: 10 }}
													alignRight={true}
												>
													<Dropdown.Toggle
														variant="secondary"
														size="sm"
														style={{
															padding: "3px 7px",
															backgroundColor:
																"rgba(255,255,255, 0.15)",
															border: "none",
														}}
														className="dropdown-toggle-rm-icon ml-2"
														id="dropdown-basic"
													>
														<img
															alt="Mais"
															src={more}
														/>
													</Dropdown.Toggle>
													<Dropdown.Menu>
														<Dropdown.Item
															onClick={() => {
																this.exportCSV();
															}}
														>
															Exportar para CSV
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</div>
										<Table
											className="mt-4"
											striped
											responsive="md"
											hover
											size="sm"
											variant="dark"
										>
											<thead>
												<tr>
													<th>Data da Leitura</th>
													<th>Status da Leitura</th>
													<th>Dispositivo</th>
												</tr>
											</thead>
											<tbody>
												{this.state.rows.map((item) => {
													return (
														<tr key={item.id}>
															<td>{item.date}</td>
															<td>
																{item.status}
															</td>
															<td>
																{item.device}
															</td>
														</tr>
													);
												})}
											</tbody>
										</Table>
									</ContainerRound>
								</Container>
							) : (
								<div>Carregando</div>
							)
						}
					</PlantSelect>
				)}
			</Subscribe>
		);
	}
}

export default withRouter(SingleSensor);
