import React, { Component } from "react";
import styled from "styled-components";
import Sensors from "../Sensors";
import Tank from "../Tank";
import { VLinkStruct, PlantStruct } from "../../types/types";
import { getAuth } from "../../services/auth";

interface State {
	user: any;
}
interface Props {
	data: VLinkStruct;
	plant: PlantStruct;
	plantIndex: Number;
}

const Container = styled.div`
	width: 100%;
	background-color: #5b5c6a;
	margin-top: 10px;
	border-radius: 10px;
	white-space: normal;
	@media (min-width: 992px) {
		overflow: auto;
		max-height: calc(725px - 70px);
	}
`;

const ProblemHealth = styled.li`
	color: #fb9700;
	font-size: 10px;
	font-weight: bold;
`;
const Title = styled.h5`
	color: #fff;
	font-size: 12px;
	font-weight: bold;
`;

export class VLink extends Component<Props, State> {
	state = {
		user: undefined,
	};
	*renderTanks() {
		for (let index = 0; index < this.props.data.tanks.length; index++) {
			const tank = this.props.data.tanks[index];
			yield (
				<Tank
					vlinkIndex={this.props.data.index}
					premium={this.props.plant.premium}
					plantIndex={this.props.plantIndex}
					plant={this.props.plant}
					vlink={this.props.data}
					key={tank.id}
					data={tank}
				/>
			);
		}
	}

	renderProblem() {
		if (this.props.data.status == 1 && !this.props.data.gprs) {
			return <div>Aguarde - Iniciando leituras.</div>;
		}
		if (this.props.data.status === 2 && !this.props.data.gprs) {
			return <div>V-Link aparenta estar desconectado da USB.</div>;
		}
		if (this.props.data.status == 3 && !this.props.data.gprs) {
			return (
				<div>
					V-Link aparenta estar desconectado da rede de alimentação elétrica.
				</div>
			);
		}
		if (this.props.data.status == 6 && !this.props.data.gprs) {
			return <div>O circuito de campo aparenta estar desconectado.</div>;
		}

		if (this.props.data.voltage != null && this.props.data.amp != null) {
			const { voltage, amp } = this.props.data;
			if (voltage < 1) {
				return (
					<div>
						O barramento da linha de campo do V-Link aparenta estar apresentando
						uma falha de funcionamento. Uma interveção técnica pode ser
						necessária.
					</div>
				);
			} else if (voltage > 1 && amp > 130) {
				return (
					<div>
						É provavel que o barramento da linha de campo (linha dos
						dispositivos) esteja em curto circuito
					</div>
				);
			} else if (voltage >= 4 && voltage <= 7 && amp < 130) {
				return (
					<div>
						A chave seletora de tensão no corpo do V-Link aparenta ter sido
						apontada para um valor de tensão incompátivel maior que a rede
						local.
					</div>
				);
			} else if (voltage > 7 && amp < 2) {
				return (
					<div>
						O barramento da linha de campo parece estar desconectado do V-Link
					</div>
				);
			}
		}
		if (this.props.data.status !== 0 && !this.props.data.gprs) {
			return <div>Erro desconhecido no V-Link</div>;
		}
	}

	*renderHealth() {
		if (this.props.data.healthStatus) {
			const { memory, rtc } = this.props.data.healthStatus;
			if (memory.not_found) {
				yield <ProblemHealth>Mémoria não encontrada</ProblemHealth>;
			}
			if (memory.log_read) {
				yield <ProblemHealth>Erro ao ler um arquivo de log</ProblemHealth>;
			}
			if (memory.log_write) {
				yield <ProblemHealth>Erro ao gravar um arquivo de log</ProblemHealth>;
			}
			if (rtc.clock_read) {
				yield <ProblemHealth>Problema na leitura do RTC</ProblemHealth>;
			}
			if (rtc.clock_write) {
				yield <ProblemHealth>Problema na escrita do RTC</ProblemHealth>;
			}
			if (rtc.low_battery) {
				yield <ProblemHealth>Bateria do RTC baixa</ProblemHealth>;
			}
			if (rtc.not_found) {
				yield <ProblemHealth>RTC não encontrado</ProblemHealth>;
			}
		}
	}

	componentDidMount() {
		this.setState({ user: getAuth().user });
	}

	render() {
		return (
			<Container className="p-2">
				<Title>
					V-Link - {this.props.data.name}{" "}
					{!this.props.data.name.startsWith("VA") &&
					(this.props.data.voltage != null || this.props.data.amp != null)
						? "-"
						: ""}{" "}
					{this.props.data.voltage != null
						? `${this.props.data.voltage.toFixed(2).toString()}V`
						: ""}{" "}
					{this.props.data.amp != null
						? `${this.props.data.amp.toFixed(2).toString()}mA`
						: ""}
				</Title>
				{this.renderProblem()}
				<ul className="list-unstyled">{Array.from(this.renderHealth())}</ul>
				<hr />

				{this.state.user != null &&
					this.props.plant.userActions.includes("ver.sensores") &&
					this.props.data.sensors.length > 0 && (
						<Sensors
							plant={this.props.plant}
							connected={
								(this.props.data.status == 0 && !this.props.data.gprs) ||
								this.props.data.gprs
							}
							premium={this.props.plant.premium}
							vlinkIndex={this.props.data.index}
							plantIndex={this.props.plantIndex}
							sensors={this.props.data.sensors}
						/>
					)}

				{this.state.user != null &&
					this.props.plant.userActions.includes("ver.indicadores") &&
					Array.from(this.renderTanks())}
			</Container>
		);
	}
}

export default VLink;
