import React, { Component } from "react";

import styled from "styled-components";
import permission from "../../assets/permission.svg";
import { Button } from "react-bootstrap";
import AddRole from "../../components/AddRole";
import api from "../../services/api";
import { RoleStruct, ActionResponseStruct } from "../../types/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";

const Container = styled.div`
	padding: 20px;
`;

const ContainerRound = styled.div`
	min-height: 90px;
	width: 100%;
	padding: 20px;
	background: #3a3848 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 15px #00000029;
	border-radius: 10px;
`;

const RoleSetting = styled.div`
	width: 100%;
	height: 380px;
	background: #32303a 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 15px #00000029;
	border-radius: 10px;

	@media (min-width: 992px) {
		height: 490px;
		width: 350px;
	}
`;

const ContainerActions = styled.div`
	max-height: 400px;
	overflow: auto;
`;

interface Props {}

interface State {
	showModalRole: Boolean;
	roles: Array<RoleStruct>;
	roleDelete: RoleStruct | unknown;
	actions: Array<ActionResponseStruct>;
	showRoleDelete: Boolean;
}
export class Roles extends Component<Props, State> {
	state = {
		showModalRole: false,
		roles: new Array<RoleStruct>(),
		roleDelete: undefined,
		actions: new Array<ActionResponseStruct>(),
		showRoleDelete: false,
	};

	componentDidMount() {
		this.initData();
	}

	async initData() {
		try {
			let responseRoles = await api.get("/roles");
			let roles = responseRoles.data.rows as Array<RoleStruct>;
			let responseActions = await api.get("/actions");
			let actions = responseActions.data.rows as Array<
				ActionResponseStruct
			>;
			this.setState({
				actions,
				roles,
			});
			this.initData();
		} catch (err) {}
	}

	async deleteRole() {
		try {
			await api.delete(
				`/roles/${
					((this.state.roleDelete as unknown) as RoleStruct)._id
				}`
			);
			this.setState({ roleDelete: null, showRoleDelete: false });
		} catch (error) {}
	}

	async updateActionRole(
		role: RoleStruct,
		action: ActionResponseStruct,
		value
	) {
		if (value) {
			let actionStruct = {
				action: action._id,
			};
			role.actions.push(actionStruct);
			this.setState({
				roles: this.state.roles,
			});
		} else {
			role.actions = role.actions.filter((item) => {
				return item.action !== action._id;
			});
			this.setState({
				roles: this.state.roles,
			});
		}
		try {
			await api.patch(`/roles/updateAction/${role._id}/${action._id}`, {
				value,
			});
		} catch (error) {
			//Ignore
		}
	}

	*renderRoles() {
		for (let index = 0; index < this.state.roles.length; index++) {
			const role = this.state.roles[index];
			yield (
				<RoleSetting
					key={role._id}
					className="ml-lg-4  ml-0 mt-4 mt-xl-4 p-4 d-flex flex-column align-items-center"
				>
					<div className="d-flex w-100 justify-content-between">
						<h3 style={{ maxWidth: "20ch" }}>{role.name}</h3>
						{role.deletable && (
							<div>
								<Button
									onClick={() => {
										this.setState({
											showRoleDelete: true,
											roleDelete: role,
										});
									}}
									size="sm"
									variant="danger"
								>
									<FontAwesomeIcon icon={faTrash} />
								</Button>
							</div>
						)}
					</div>
					<ContainerActions className="mt-5">
						<ul className="list-unstyled">
							{Array.from(this.renderActions(role))}
						</ul>
					</ContainerActions>
				</RoleSetting>
			);
		}
	}

	containsAction(action: ActionResponseStruct, role: RoleStruct) {
		for (let index = 0; index < role.actions.length; index++) {
			const actionRole = role.actions[index];
			if (actionRole.action === action._id) {
				return true;
			}
		}
		return false;
	}

	*renderActions(role: RoleStruct) {
		for (let index = 0; index < this.state.actions.length; index++) {
			const action = this.state.actions[index];
			yield (
				<li key={action._id} className="mt-2">
					<div className="form-check d-flex justify-content-between">
						<input
							type="checkbox"
							checked={this.containsAction(action, role)}
							onChange={(item) => {
								this.updateActionRole(
									role,
									action,
									item.target.checked
								);
							}}
							id={`action-${role._id}-${action._id}`}
							className="css-checkbox"
						/>
						<label
							htmlFor={`action-${role._id}-${action._id}`}
							className="css-label"
						>
							{action.description}
						</label>
					</div>
				</li>
			);
		}
	}

	render() {
		return (
			<div>
				<Container>
					<ContainerRound className="d-flex align-items-center justify-content-between">
						<div className="d-flex align-items-center">
							<img alt="Papéis" height="32" src={permission} />
							<h2 className="ml-3 mb-0">Papéis</h2>
						</div>
						<Button
							onClick={() => {
								this.setState({ showModalRole: true });
							}}
						>
							Adicionar
						</Button>
					</ContainerRound>
					<ContainerRound
						className="p-2 d-flex flex-wrap"
						style={{ marginTop: 20 }}
					>
						{Array.from(this.renderRoles())}
					</ContainerRound>
					<AddRole
						onHide={() => {
							this.setState({ showModalRole: false });
							this.initData();
						}}
						show={this.state.showModalRole}
					/>
				</Container>
				{this.state.roleDelete != null && (
					<Modal show={this.state.showRoleDelete}>
						<Modal.Header closeButton>
							<Modal.Title>Remover Papél</Modal.Title>
						</Modal.Header>

						<Modal.Body>
							<p>
								Tem certeza que seja remover esse papél{" "}
								{
									((this.state
										.roleDelete as unknown) as RoleStruct)
										.name
								}{" "}
								?
							</p>
						</Modal.Body>
						<Modal.Footer>
							<Button
								onClick={() => {
									this.setState(
										{ showRoleDelete: false },
										() => {
											this.setState({
												roleDelete: null,
											});
										}
									);
								}}
								variant="secondary"
							>
								Não
							</Button>
							<Button
								onClick={() => {
									this.deleteRole();
								}}
								variant="primary"
							>
								Sim
							</Button>
						</Modal.Footer>
					</Modal>
				)}
			</div>
		);
	}
}

export default Roles;
