import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	@media (min-device-width: 960px) {
		padding-top: 100px;
	}
	height: 100%;
	
`;

export const Form = styled.form`
	background: #3a3848 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 15px #00000029;
	border-radius: 10px;
	width: 375px;
	min-height: 630px;
	@media (max-device-width: 480px) {
		width: 100vw;
		border-radius: 0px;
		box-shadow: none;
	}
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	.forgot-password {
		font-size: 12px;
		background: none;
		border: none;
		opacity: 0.5;
		color: #fff;
		text-decoration: underline;
	}
	h1 {
		font-size: 20px;
		font-weight: bold;
		letter-spacing: 0;
		color: #ffffff;
	}
	label {
		width: 100%;
		font-size: 14px;
		font-weight: bold;
		text-align: left;
		letter-spacing: 0;
		color: #ffffff;
		opacity: 0.5;
	}
	img {
		width: 100px;
		margin: 10px 0 40px;
	}
	.form-check {
		width: 100%;
		margin-top: 30px;
		padding: 0;

		label {
			font-size: 14px;
			color: #2d7aed;
			opacity: 1;
			font-weight: bold;
		}
	}
	input[type="text"],
	input[type="email"],
	input[type="password"] {
		height: 40px;
		background: #32303a 0% 0% no-repeat padding-box;
		border-radius: 10px;
		margin-bottom: 15px;
		padding: 10px 20px;
		color: #fff;
		width: 100%;
		border: none;
		&::placeholder {
			color: #fff;
		}
	}
	hr {
		margin: 20px 0;
		border: none;
		border-bottom: 1px solid #cdcdcd;
		width: 100%;
	}
	a {
		font-size: 16;
		font-weight: bold;
		color: #999;
		text-decoration: none;
	}
	.buttons-actions {
		margin-top: 20px;
		width: 100%;
	}

	button {
		font-size: 14px;
		padding: 5px 20px;
		border-radius: 10px;
		min-width: 120px;
		font-weight: bold;
	}
`;
